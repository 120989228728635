import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import {
  CalendarMonthViewDay,
  CalendarEventTitleFormatter,
  CalendarEventTimesChangedEvent,
  CalendarDayViewBeforeRenderEvent,
  CalendarView,
  DAYS_OF_WEEK,
  CalendarDateFormatter
} from 'angular-calendar';
import { GenericService } from 'app/services/generic/generic.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { FunctionsService } from 'app/services/generic/functions.service';

@Component({
  selector: 'app-table-agenda',
  templateUrl: './table-agenda.component.html',
  styleUrls: ['./table-agenda.component.scss']
})
export class TableAgendaComponent implements OnInit {
  @Input() structure = null;
  @Input() index: number;
  @Input() indexTable: number;
  @Input() group: any;
  @Input() oldBdField: any;
  @Input() oldValue: any;
  @Input() element: any;
  loaded = false;
  columnOnClick = null;

   // Estas son las vistas disponibles en el calendario
   CalendarView = CalendarView;
  
   // Esta variable define cuál vista se está mostrando (Month, Week, Day)
   view: CalendarView = CalendarView.Week;
 
   // Define la fecha base para la vista
   viewDate: Date = new Date();
 
   // Activa / desactiva panel de eventos en modo mes
   activeDayIsOpen: boolean = true;
 
   // Control de eventos (por ejemplo, para refrescar la vista)
   refresh: Subject<any> = new Subject();
 
   // Idioma y configuración de inicio de semana, fin de semana, etc.
   locale: string = 'es';
   weekStartsOn: number = 1;  // 0 = Domingo, 1 = Lunes, etc.
   weekendDays: number[] = [0, 6]; // Define qué días son fin de semana
   dayStartHour: number = 7;  // Hora de inicio para la vista de día/semana
   dayEndHour: number = 20;   // Hora de fin para la vista de día/semana
   hourSegments: number = 1;  // Por ejemplo, para dividir la hora en 30 min
   hourSegmentHeight: number = 70; // Ajusta el alto de cada segmento

   eventsWithNoDate = [];
 
   // Aquí guardamos los eventos
   customEvents = [];

   showHeights = false;

  constructor(public genericService: GenericService, private functionsControllerService: FunctionsControllerService, private functionsService: FunctionsService) { }

  ngOnInit(): void {
    let events = this.genericService.cloneVariable(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].dataSourceTables[this.indexTable]['filteredData']);
    console.log('Eventos:', events);
    console.log('Estructura:', this.structure[this.index]);
    for(let c in this.structure[this.index]['child']) {
      if(this.structure[this.index]['child'][c]['id_function'] > 0) {
        this.columnOnClick = this.structure[this.index]['child'][c];
        break;
      }
    }
    // Una vez que los tengas, mapeas a la estructura de CalendarEvent:
    events.forEach(item => {
      // const fechaStringInicio = item['65761-fecha_inicio'];
      const fechaStringInicio = item['1947-fecha_inicio'];
      
      // Si no existe la fecha de inicio, lo excluimos y metemos en this.list
      if (!fechaStringInicio || fechaStringInicio === '') {
        this.eventsWithNoDate.push(item);
        return; // no creamos evento
      }
      
      let fechaStringFin = item['65762-fecha_fin'] || null;
      
      // Parseo manual de inicio
      const [datePartIni, timePartIni] = fechaStringInicio.split(' ');
      const [dayIni, monthIni, yearIni] = datePartIni.split('-').map(Number);
      const [hourIni, minuteIni] = timePartIni.split(':').map(Number);
      const fechaInicio = new Date(yearIni, monthIni - 1, dayIni, hourIni, minuteIni);
    
      // Parseo o cálculo de fechaFin
      let fechaFin: Date;
      if (fechaStringFin && fechaStringFin !== '') {
        const [datePartFin, timePartFin] = fechaStringFin.split(' ');
        const [dayFin, monthFin, yearFin] = datePartFin.split('-').map(Number);
        const [hourFin, minuteFin] = timePartFin.split(':').map(Number);
        fechaFin = new Date(yearFin, monthFin - 1, dayFin, hourFin, minuteFin);
      } else {
        // fechaFin = fechaInicio + 5 minutos
        fechaFin = new Date(fechaInicio.getTime() + 5 * 60 * 1000);
      }
    
      this.customEvents.push({
        start: fechaInicio,
        end: fechaFin,
        title: item['65747-titulo'],
        color: { primary: '#ad2121', secondary: '#FAE3E3' },
        data: item
      });
    });

    // Indica que ya cargamos los datos
    this.loaded = true;
  }

  // Si quieres manejar el clic en un día, por ejemplo, en la vista de mes:
  dayClicked(day: CalendarMonthViewDay): void {
    console.log('Día clicado:', day.date);
    // Podrías, por ejemplo, togglear el panel de eventos del día
    if (day.events.length > 0 || !this.activeDayIsOpen) {
      this.activeDayIsOpen = !this.activeDayIsOpen;
    }
  }

  // Cuando se hace clic en un evento
  handleEvent(action: string, event: any): void {
    switch (action) {
      case 'Clicked':
        this.onClickEditActivity(event);
        break;
      case 'HourClicked':
        this.onClickAddActivity(event);
        break;
      case 'Dropped or resized':
        // this.refresh.next();
        break;
    }
  }

  // Cuando se arrastra o se cambia la duración de un evento
  eventTimesChanged(evento: any): void {
    // Lógica para cambiar hora de inicio/fin del evento
    // evento.event = el evento que se ha movido
    // evento.newStart / evento.newEnd = nuevas fechas de inicio/fin
    console.log('Evento arrastrado / cambiado: ', evento);
  }

  // Ejemplos de hooks para manipular vista mes/semana/día antes de renderizar
  beforeMonthViewRender(evento: any): void {
    console.log('Mes: antes de renderizar', evento);
  }
  beforeMonthViewRenderWeek(evento: any): void {
    console.log('Semana: antes de renderizar', evento);
  }
  beforeMonthViewRenderDay(evento: any): void {
    console.log('Día: antes de renderizar', evento);
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  onHourSegmentHeightChange(newValue: number): void {
    //DEBE GUARDARLO EN PARAMS Y AL HACER INIT CARGARLO
  }

  onHourSegmentsChange(newValue: number): void {
    //DEBE GUARDARLO EN PARAMS Y AL HACER INIT CARGARLO
  }

  showHeightsFunction() {
    this.showHeights = !this.showHeights;
  }

  onClickEditActivity(event: any) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.sourceEvent.ctrlKey || event.sourceEvent.metaKey);
    this.functionsControllerService.getFunction(this.structure[this.index], event.event.data);
  }

  onClickAddActivity(event: any) {
    console.log('Añadir actividad:', event, this.columnOnClick, this.functionsService.formatDate(event['date'], true));
    if(this.columnOnClick) {
      let value = this.functionsService.formatDate(event['date']);
      this.functionsService.addWindowParam(value, 1, "actividades_calendario", "fecha_inicio", 89); // Hay que poner en el header porque el Dialog esta en el Header --> Como hacemos esto dinámico???
      this.columnOnClick['refresh_params'] = 2; // --> No hace caso y no lo pasa
      this.columnOnClick['isPressingControl'] = event !== undefined && (event.sourceEvent.ctrlKey || event.sourceEvent.metaKey);
      this.functionsControllerService.getFunction(this.columnOnClick);
    }
  }
}
