import { Component, OnInit } from '@angular/core';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-load-insert-info',
  templateUrl: './load-insert-info.component.html',
  styleUrls: ['./load-insert-info.component.scss']
})
export class LoadInsertInfoComponent implements OnInit {

  public insertsDebugList = null;

  constructor(
    public functionsService: FunctionsService,
    public endpointService: EndpointService,
    public genericService: GenericService
  ) { }

  ngOnInit(): void {
    let structure = this.genericService.findElementWithId(this.genericService.idFaToGetInfo, false, false, true);
    const v = this.insertFormDataInfo(structure);
    if (v != null) {
      v.subscribe((data) => {
        this.insertsDebugList = data['response'];
      });
    }
  }
  
  public insertFormDataInfo(structure) {
    this.functionsService.formsArray(structure, null, false, true);
    let dataAcc = this.genericService.getDataAccount(false);
    let pkWindowParam = null;
    let pkWindowParamTable = null;
    let pkWindowParamField = null;
    let initialFa = this.genericService.getActualInitialFA(structure);
    if(this.genericService.paramControlVariables[initialFa] && this.genericService.paramControlVariables[initialFa]['params'] && this.genericService.paramControlVariables[initialFa]['indexParam'] !== undefined && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']] && this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]) {
      if(this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParam']) pkWindowParam = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParam'];
      if(this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamTable']) pkWindowParamTable = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamTable'];
      if(this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamField']) pkWindowParamField = this.genericService.paramControlVariables[initialFa]['params'][this.genericService.paramControlVariables[initialFa]['indexParam']]['pkWindowParamField'];
    }
    dataAcc['pkWindowParam'] = pkWindowParam;
    dataAcc['pkWindowParamTable'] = pkWindowParamTable;
    dataAcc['pkWindowParamField'] = pkWindowParamField;
    let interns = null;
    interns = this.genericService.getInternParam(structure, []);
    let userChanges = this.genericService.getFormsCurrentPage();
    return this.endpointService.insertFormDataInfo(dataAcc, this.functionsService.tmpArray, this.functionsService.removed, this.functionsService.imageUpload, interns, userChanges);
  }
}
