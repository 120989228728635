<ng-container *ngIf="idQuery && loaded && groupsApplied">
  <div *ngIf="!functionsService.downloadingStaticPage" fxLayout="column" fxFlex="100" [ngClass]="{'hide-options-header-table': !(search == true || paginatorOptions !== null) && tableData.length > 0}" style="position: relative;">
    <div fxLayout="row wrap" *ngIf="(search == true || paginatorOptions !== null) && tableDataInitial.length > 0" [ngClass]="'bottom-table-generic bottom-table-generic-' + structure[index]['id_functional_area']" ngClass.sm="bottom-table-generic-mb" ngClass.xs="bottom-table-generic-mb">
      <div fxFlex.sm="100" fxFlex.xs="100" *ngIf="search == true" class="bottom-table2" style="display: flex;">
        <mat-form-field appearance="outline" *ngIf="search && tableData">
          <mat-chip-list #chipList aria-label="Filter selection">
            <!-- Recorremos el array con trackBy para evitar re-renders innecesarios -->
            <mat-chip
              *ngFor="let filter of chipFilterArray; let i = index; trackBy: trackByFn"
              [removable]="editIndex !== i"
              (removed)="removeChip(filter)"
            >
              <!-- Si editIndex coincide con el i actual, mostramos un input; si no, mostramos el texto normal -->
              <ng-container *ngIf="editIndex === i; else showChip">
                <input
                  [(ngModel)]="chipFilterArray[i]"
                  (keydown)="onEditKeyDown($event, i)"
                  (blur)="finishEditing(i)"
                  (click)="$event.stopPropagation()"
                  class="edit-chip-input"
                  placeholder="Editar filtro"
                  autofocus
                />
              </ng-container>
          
              <!-- Vista normal del chip (cuando NO se edita) -->
              <ng-template #showChip>
                <!-- Con un solo clic se activa la edición -->
                <span (click)="editChip(i)">
                  {{ filter }}
                </span>
              </ng-template>
          
              <!-- Botón para eliminar el chip (solo aparece si NO estamos editando ese chip) -->
              <button matChipRemove *ngIf="editIndex !== i">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          
            <!-- Campo de entrada para agregar nuevos chips -->
            <input
              placeholder="Añade un filtro y presiona ENTER"
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeyCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addChip($event)"
            />
          </mat-chip-list>
          <button *ngIf="arrayFiltersFields.length > 0" class="table-filter" [matMenuTriggerFor]="filters" [matBadge]="filtersCount" [matBadgeHidden]="filtersCount < 1" matSuffix mat-icon-button aria-label="Filter" matTooltip="Filtrar resultados">
            <mat-icon style="color: #000;">tune</mat-icon>
          </button>
          <button class="table-view" [matMenuTriggerFor]="views" matBadge="!" [matBadgeHidden]="!structure[index]['hasViewActive']" matSuffix mat-icon-button aria-label="Views" matTooltip="Vistas">
            <mat-icon style="color: #000;">table_view</mat-icon>
          </button>
          <button class="table-group" [matMenuTriggerFor]="groups" matBadge="!" [matBadgeHidden]="!structure[index]['hasGroupActive']" matSuffix mat-icon-button aria-label="Groups" matTooltip="Agrupaciones">
            <mat-icon style="color: #000;">table_chart</mat-icon>
          </button>
          <button *ngIf="structure[index]['canExportTables'] && this.indexTable == 0" matSuffix mat-icon-button aria-label="Download" (click)="triggerExport(); $event.stopPropagation()" matTooltip="Descargar contenido">
            <mat-icon style="color: #000;">download</mat-icon>
          </button>
        </mat-form-field>
        <div matTooltip="Crear nueva visualización" class="button-create-dashboards" (click)="this.openCreateDashboardDialog(structure[index]['id_functional_area'])" matBadge="BETA">
          <mat-icon style="color: #000;">add</mat-icon>
          <mat-icon style="color: #000; margin-left: -6px;">analytics</mat-icon>
        </div>
        
        <mat-menu #filters="matMenu">
          <button *ngIf="filtersCount > 0" mat-menu-item (click)="resetFilters(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon class="reset-table-generic">backspace</mat-icon>Limpiar filtros
          </button>
          <button mat-menu-item (click)="openFilterTable(0); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon style="color: #000;">add</mat-icon>Nuevo filtro
          </button>
          <ng-container *ngIf="!genericService.isReloadingTabsMenu && this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['allFilters']">
            <ng-container *ngFor="let e of this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['allFilters']; let i = index">
              <ng-container *ngIf="e">
                <button mat-menu-item (click)="selectFilters(e, i); $event.stopPropagation()" style="text-transform: capitalize" [ngClass]="e.active ? 'highlight' : ''">
                  <mat-icon (click)="deleteFilter(i); $event.stopPropagation()" style="color: red !important;" matTooltip="Eliminar">delete</mat-icon> <mat-icon (click)="editFilter(e); $event.stopPropagation()" matTooltip="Editar">edit</mat-icon> {{ e['name'] }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-menu>

        <mat-menu #views="matMenu">
          <button *ngIf="structure[index]['hasViewActive']" mat-menu-item (click)="resetViews(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon class="reset-table-generic">backspace</mat-icon>Deseleccionar vista
          </button>
          <button mat-menu-item (click)="openViewsTable(0); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon style="color: #000;">add</mat-icon>Nueva vista
          </button>
          <ng-container *ngIf="!genericService.isReloadingTabsMenu && this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views']">
            <ng-container *ngFor="let e of this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['views']; let i = index">
              <ng-container *ngIf="e">
                <button mat-menu-item (click)="selectView(e); $event.stopPropagation()" style="text-transform: capitalize" [ngClass]="e.active ? 'highlight' : ''">
                  <mat-icon (click)="deleteView(i); $event.stopPropagation()" style="color: red !important;" matTooltip="Eliminar">delete</mat-icon> <mat-icon (click)="editView(e); $event.stopPropagation()" matTooltip="Editar">edit</mat-icon> {{ e['name'] }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-menu>

        <mat-menu #groups="matMenu">
          <button *ngIf="structure[index]['hasGroupActive']" mat-menu-item (click)="resetGroups(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon class="reset-table-generic">backspace</mat-icon>Deseleccionar agrupación
          </button>
          <button mat-menu-item (click)="openGroupsTableBottomsheet(); $event.stopPropagation()" style="text-transform: capitalize">
            <mat-icon style="color: #000;">add</mat-icon>Nueva agrupación
          </button>
          <ng-container *ngIf="!genericService.isReloadingTabsMenu && this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['groups']">
            <ng-container *ngFor="let e of this.genericService.paramControlVariablesFAs[actual_id_functional_area][this.genericService.paramControlVariables[actual_id_functional_area]['indexParam']][idFa]['groups']; let i = index">
              <ng-container *ngIf="e">
                <button mat-menu-item (click)="selectGroup(e); $event.stopPropagation()" style="text-transform: capitalize" [ngClass]="e.active ? 'highlight' : ''">
                  <mat-icon (click)="deleteGroup(i); $event.stopPropagation()" style="color: red !important;" matTooltip="Eliminar">delete</mat-icon> <mat-icon (click)="editGroup(e); $event.stopPropagation()" matTooltip="Editar">edit</mat-icon> {{ e['name'] }}
                </button>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-menu>
      </div>
    </div>
    <ng-container *ngIf="displayedColumnsGroup && displayedColumnsGroup.selectedColumn && groupedData && groupedData.length; else showNormalTable">
      <!-- Si hay agrupación, renderizamos los grupos recursivamente -->
      <ng-container *ngIf="doFilterInit()"></ng-container>
      <ng-container *ngTemplateOutlet="recursiveGroupTemplate; context: { groups: groupedData }"></ng-container>
    </ng-container>
    
    <ng-template #showNormalTable>
      <!-- Si no hay agrupación, renderizamos la tabla normal -->
      <ng-container *ngTemplateOutlet="normalTable; context: { indexTable: 0, group: {} }"></ng-container>
    </ng-template>

    <ng-template #recursiveGroupTemplate let-groups="groups">
      <div style="padding-bottom: 10px; width: 100%;">
        <span *ngIf="checkNoValuesFiltersGroups(groups); else showFieldGrouped" class="title-expansion-table">
          <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
            <span [innerHTML]="this.structure[this.index]['text'] | safeHtml"></span>
          </ng-container>
          <ng-template #noText>
            No se han encontrado resultados.
          </ng-template>
        </span>
        <ng-template #showFieldGrouped>
          <div class="title-expansion-table" *ngIf="groups && groups[0] && groups[0].columnName">
            Agrupado por: <b>{{ groups[0].columnName }}</b>
          </div>
        </ng-template>
        <div *ngFor="let group of groups" class="content-expansion-table">
          <mat-expansion-panel #panel *ngIf="getNumbersTable(group)" (opened)="this.clickPanel(group, true)" (closed)="this.clickPanel(group, false)" [expanded]="group['dataSaved'] && group['dataSaved'].expanded">
            <mat-expansion-panel-header (click)="recalculateHeights()">
              <mat-panel-title>
                <ng-container *ngIf="group.key == 'isbooleantrue'">
                  <span style="margin-left: 0;" class="material-icons checked-generic">check_circle</span>
                </ng-container>
                <ng-container *ngIf="group.key == 'isbooleanfalse'">
                  <span style="margin-left: 0;" class="material-icons denied-generic">cancel</span>
                </ng-container>
                <ng-container *ngIf="group.key != 'isbooleantrue' && group.key != 'isbooleanfalse'">
                  <span style="font-weight: 400;" [innerHTML]="group.key | safeHtml"></span> 
                </ng-container>
                <span style="margin-left: 4px; font-weight: 600;">({{ group.count }})</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
      
            <!-- Contenido que se carga sólo al expandir el panel -->
            <ng-container *ngIf="panel.expanded || (group.subGroups && group.subGroups.length > 0)">
              <ng-container *ngIf="group.subGroups && group.subGroups.length > 0">
                <!-- Llamada recursiva al template para subgrupos -->
                <ng-container *ngTemplateOutlet="recursiveGroupTemplate; context: { groups: group.subGroups }"></ng-container>
              </ng-container>
      
              <ng-container *ngIf="!group.subGroups">
                <!-- Aquí llamamos al ng-template de la tabla -->
                <ng-container *ngTemplateOutlet="normalTable; context: { indexTable: group.indexTable, group: group }"></ng-container>
              </ng-container>
            </ng-container>
          </mat-expansion-panel>
        </div>
      </div>
    </ng-template>
    
    <ng-template #showNormalTableOnly>
      <!-- Si no hay agrupación, renderizamos la tabla normal -->
      <ng-container *ngTemplateOutlet="normalTable; context: { indexTable: 0, group: {} }"></ng-container>
    </ng-template>
    
    <ng-template #normalTable let-group="group" let-indexTable="indexTable" let-getHeightV="getHeightV" let-getHeightTable2V="getHeightTable2V">
      <!-- Aquí farem els ngFors dels tabs si n'hi ha -->
       <ng-container *ngIf="!this.tabsInfoTable || !this.tabsInfoTable.length || this.tabsInfoTable.length == 0">
          <app-table class="tables-no-groups-info"
            *ngIf="genericService.tablesInformation[structure[index]['id_functional_area']]
                    && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables
                    && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables[indexTable]"
            [indexTable]="indexTable" [structure]="structure" [index]="index" [group]="group" [oldBdField]="oldBdField" [oldValue]="oldValue">
        </app-table>
       </ng-container>
       <ng-container *ngIf="this.tabsInfoTable && this.tabsInfoTable.length && this.tabsInfoTable.length > 0">
         <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChange($event)">
           <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="icons-tables-types">table</mat-icon>#
            </ng-template>
             <app-table
               *ngIf="genericService.tablesInformation[structure[index]['id_functional_area']]
                       && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables
                       && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables[indexTable]"
               [indexTable]="indexTable" [structure]="structure" [index]="index" [group]="group" [oldBdField]="oldBdField" [oldValue]="oldValue" class="tables-groups-info" [indexTabLoaded]="selectedTabIndex">
             </app-table>
           </mat-tab>
           <mat-tab *ngFor="let tab of tabsInfoTable; let i = index; trackBy: trackByFn">
             <ng-template mat-tab-label>
              <mat-icon class="icons-tables-types">
                <ng-container [ngSwitch]="tab.id_type">                  
                  <ng-container *ngSwitchCase="1">analytics</ng-container>
                  <ng-container *ngSwitchCase="2">calendar_today</ng-container>                  
                  <ng-container *ngSwitchCase="3">view_timeline</ng-container>                  
                  <ng-container *ngSwitchCase="4">calculate</ng-container>
                  <ng-container *ngSwitchDefault>table</ng-container>
                </ng-container>
              </mat-icon>
               {{ tab.label }}
             </ng-template>
             
             <!-- Cargar contenido solo si este tab está seleccionado -->
             <ng-container *ngIf="selectedTabIndex === i+1">
              <div *ngIf="this.changeFilter" style="width: 100%; justify-content: center; display: flex;">
                <mat-spinner></mat-spinner>
              </div>
              <div *ngIf="!this.changeFilter" fxLayout="row wrap">
                <div fxFlex="100" class="buttons-graphs-tables-generic">
                 <span *ngIf="checkDisplayPermissions(i)">
                   <span class="generic-buttons-red">
                     <button mat-raised-button matTooltip="Eliminar visualización" (click)="openDeleteDashboardDialog(i)">
                       <mat-icon>delete</mat-icon>Eliminar
                     </button>
                   </span>
                   <span class="generic-buttons-blue">
                     <button mat-raised-button matTooltip="Editar visualización" (click)="openEditDashboardDialog(i)">
                       <mat-icon>edit</mat-icon>Editar
                     </button>
                   </span>
                 </span>
                 <span class="generic-buttons-blue" *ngIf="!checkDisplayPermissions(i)" >
                   <button mat-raised-button matTooltip="Información visualización" (click)="openInfoDashboardDialog(i)">
                     <mat-icon>info</mat-icon>Información
                   </button>
                 </span>
                </div>
                <div fxFlex="100">
                  <!-- Switch por tipo de contenido -->
                  <ng-container [ngSwitch]="tab.id_type">
                    
                    <!-- Caso para id_type 1: componente de tabla -->
                    <span *ngSwitchCase="1" fxLayout="row wrap" fxLayoutAlign="start start" fxFlex="100">
                    <span *ngFor="let ele of tab.extraInfo; let i = index; trackBy: trackByFn" [fxFlex]="ele.width > 0 && ele.width < 100 ? ele.width : 100" [fxFlex.sm]="ele.width > 0 && ele.width < 25 ? 50 : 100" fxFlex.xs="100" style="box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2); padding: 20px;">
                      <app-table-graphs *ngIf="genericService.tablesInformation[structure[index]['id_functional_area']] && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables[indexTable]"
                          [indexTable]="indexTable" [structure]="structure" [index]="index" [group]="group" [oldBdField]="oldBdField" [oldValue]="oldValue" [element]="ele"></app-table-graphs>
                    </span>
                  </span>
                    
                    <!-- Puedes agregar otros casos para diferentes id_type -->
                    <ng-container *ngSwitchCase="2">
                      <app-table-agenda *ngIf="genericService.tablesInformation[structure[index]['id_functional_area']] && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables && genericService.tablesInformation[structure[index]['id_functional_area']].dataSourceTables[indexTable]"
                          [indexTable]="indexTable" [structure]="structure" [index]="index" [group]="group" [oldBdField]="oldBdField" [oldValue]="oldValue" [element]="ele"></app-table-agenda>
                    </ng-container>
                    
                    <!-- Puedes agregar otros casos para diferentes id_type -->
                    <ng-container *ngSwitchCase="3">
                      <!-- Otro componente o contenido para id_type 2 -->
                      <p>Contenido para id_type 3: GANTT</p>
                    </ng-container>
                    
                    <!-- Puedes agregar otros casos para diferentes id_type -->
                    <ng-container *ngSwitchCase="4">
                      <!-- Otro componente o contenido para id_type 2 -->
                      <p>Contenido para id_type 4: EXCEL OPERATIONS</p>
                    </ng-container>
      
                    <!-- Caso por defecto -->
                    <ng-container *ngSwitchDefault>
                      <!--<p>Contenido por defecto para este tab</p>-->
                    </ng-container>
                  
                  </ng-container>
                </div>
              </div>
             </ng-container>
           </mat-tab>
         </mat-tab-group>
       </ng-container>
    </ng-template>
  </div>
  <ng-container *ngIf="functionsService.downloadingStaticPage">
    <app-table [indexTable]="0" [structure]="structure" [index]="index" [oldBdField]="oldBdField" [oldValue]="oldValue"></app-table>
  </ng-container>
  
  <div *ngIf="!loadedFilter" fxLayoutAlign="center center" fxFlex="100">
    <mat-spinner></mat-spinner>
  </div>
</ng-container>