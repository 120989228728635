import { Component, Input, OnInit } from '@angular/core';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import mermaid from 'mermaid';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { RoutingService } from 'app/services/routing/routing.service';

@Component({
  selector: 'app-db-schema-custom',
  templateUrl: './db-schema-custom.component.html',
  styleUrls: ['./db-schema-custom.component.scss']
})
export class DbSchemaCustomComponent implements OnInit {

  mermaidDefinition: string;
  public loaded = false;
  showOptions = 1;
  id_schema = 1;
  myZoom = 4000;
  lastData;
  public showAllColumns = true;

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;
  @Input() type: any = null;

  constructor(
    private endpointService: EndpointService,
    public authService: AuthService,
    public functionsService: FunctionsService,
    public functionsControllerService: FunctionsControllerService,
    public genericService: GenericService,
    private routingService: RoutingService
  ) { }

  ngOnInit(): void {
    const myZoom = JSON.parse(localStorage.getItem('myZoomDbSchema'));
    if (myZoom != null) {
      this.myZoom = myZoom;
    }
    const showAllColumns = JSON.parse(localStorage.getItem('showAllColumnsDbSchema'));
    if (showAllColumns != null) {
      this.showAllColumns = showAllColumns;
    }
    const showOptions = JSON.parse(localStorage.getItem('showOptionsDbSchema'));
    if (showOptions != null) {
      this.showOptions = showOptions;
    }
    if (this.type === 'window') {
      this.loadStructureFromWindow();
    } else if (this.type === 'table') {
      this.loadStructureFromTable();
    } else {
      let routeParams = this.genericService.paramControlVariables[
        this.structure[this.index]['id_functional_parent_initial']
      ]['params'][
        this.genericService.paramControlVariables[
          this.structure[this.index]['id_functional_parent_initial']
        ]['indexParam']
      ]['input'];

      for (let k in routeParams) {
        if (
          routeParams[k]['id_db'] === 3 &&
          routeParams[k]['bd_table'] === 'db_schemas' &&
          routeParams[k]['bd_field'] === 'id_schema'
        ) {
          this.id_schema = routeParams[k]['value'];
          this.loadStructureFromSchema();
          break;
        }
      }
    }
  }

  changeShowOptions() {
    setTimeout(() => {
      localStorage.setItem('showOptionsDbSchema', JSON.stringify(this.showOptions));
      if (this.type === 'window') {
        this.loadStructureFromWindow();
      } else if (this.type === 'table') {
        this.loadStructureFromTable();
      } else {
        this.loadStructureFromSchema();
      }
    });
  }

  loadStructureFromSchema() {
    this.loaded = false;
    this.endpointService.getDBSchema(this.id_schema, this.showOptions).subscribe((resp) => {
      if (resp['response'] != null && resp['response'] !== '') {
        this.lastData = resp['response'];
        this.loadStructure();
      }
    });
  }

  loadStructureFromWindow() {
    let obj = this.genericService.infoForWindow[this.genericService.idFaToGetInfo];
    this.loaded = false;
    if (this.genericService.staticHTML) {
      obj = this.genericService.infoForWindowForStatic[this.genericService.idFaToGetInfo];
    }
    this.endpointService.getDBSchemaWindow(obj, this.showOptions).subscribe((resp) => {
      if (resp['response'] != null && resp['response'] !== '') {
        this.lastData = resp['response'];
        this.loadStructure();
      }
    });
  }

  loadStructureFromTable() {
    let fa = this.genericService.findElementWithId(this.genericService.idFaToGetInfo, false, false, true);
    if (fa['informationTables'] === undefined) this.getTableSchema(fa);
    this.loaded = false;
    this.endpointService.getDBSchemaWindow(fa['informationTables'], this.showOptions).subscribe((resp) => {
      if (resp['response'] != null && resp['response'] !== '') {
        this.lastData = resp['response'];
        this.loadStructure();
      }
    });
  }

  getTableSchema(element) {
    element['informationTables'] = {};
    element['child'].forEach((child: any) => {
      if (child['id_db'] && child['bd_table']) {
        if (!element['informationTables'][child['id_db']]) {
          element['informationTables'][child['id_db']] = {
            tables: {},
            tablesString: ''
          };
        }

        if (!element['informationTables'][child['id_db']]['tables'][child['bd_table']]) {
          const sanitizedTable = child['bd_table']
            .replace(/ /g, '')
            .replace(/'/g, '')
            .replace(/"/g, '');

          element['informationTables'][child['id_db']]['tables'][child['bd_table']] = true;
          element['informationTables'][child['id_db']]['tablesString'] += `${sanitizedTable}, `;
        }
      }
    });
  }

  loadStructure() {
    let data = this.lastData;
    const tables = Array.from(new Set(data.fields.map(pk => pk.TABLE_NAME)));
    const relations = data.foreignKeys;

    // Crear la definición de Mermaid con init personalizado
    let definition = `%%{init: {
                      'theme': 'base',
                      'themeVariables': {
                        'darkMode': true,
                        'fontSize': '12px',
                        'primaryColor': '${this.authService.primary_color}60',
                        'primaryTextColor': '#000',
                        'secondaryTextColor': '#000',
                        'secondaryColor': '${this.authService.tertiary_color}99',
                        'tertiaryColor': '${this.authService.secondary_color}99',
                        'primaryBorderColor': '${this.authService.primary_color}80',
                        'secondaryBorderColor': '${this.authService.tertiary_color}80'
                      }
                    }}%%\n`;

    definition += 'erDiagram\n';

    // Definir las tablas
    tables.forEach(table => {
      // Abrimos la definición de la tabla
      definition += `${table} {\n`;

      // Recolectamos los fields de esa tabla
      const fields = data.fields.filter(pk => pk.TABLE_NAME === table);

      fields.forEach(pk => {
        // 1) Parte PRINCIPAL: pk.DATA_TYPE + pk.COLUMN_NAME (+ PK/FK/UNI)
        let fieldLine = `${pk.DATA_TYPE} ${pk.COLUMN_NAME}`;
        if (pk.COLUMN_KEY === 'PRI') fieldLine += ' PK';
        if (pk.COLUMN_KEY === 'MUL') fieldLine += ' FK';
        if (pk.COLUMN_KEY === 'UNI') fieldLine += ' UNI';

        // 2) Creamos la “segunda línea” usando un array de datos, 
        //    que uniremos con barras verticales " | " para simular "columnas".
        if(this.showAllColumns) {
          const detailParts: string[] = [];
  
          // Extra
          if (pk.EXTRA) {
            detailParts.push(`${pk.EXTRA}`);
          }
  
          // Default
          if (pk.COLUMN_DEFAULT !== null && pk.COLUMN_DEFAULT !== undefined) {
            detailParts.push(`Default: ${pk.COLUMN_DEFAULT}`);
          }
          // Type
          if (pk.LENGTH_INFO && pk.LENGTH_INFO != "N/A") {
            detailParts.push(`Length: ${pk.LENGTH_INFO}`);
          }
          // Null
          if (pk.IS_NULLABLE === 'YES') {
            //detailParts.push('ACCEPT NULL');
          } else {
            detailParts.push('NOT NULL');
          }
          // Comentario
          if (pk.COLUMN_COMMENT) {
            detailParts.push(`Comment: ${pk.COLUMN_COMMENT}`);
          }
  
          // Si hay algo que mostrar como “columnas”:
          if (detailParts.length > 0) {
            // Lo unimos con " | " en vez de comas
            const lineExtra = detailParts.join(' | ');
            // Ejemplo final:  "Default: NULL | Type: int(11) | ACCEPT NULL | Extra: auto_increment"
            fieldLine += ` "${lineExtra}"`;
          }
        }

        definition += fieldLine + '\n';
      });

      definition += '}\n';
    });

    // Definir las relaciones
    relations.forEach(rel => {
      definition += `${rel.TABLE_NAME} ||--|{ ${rel.REFERENCED_TABLE_NAME} : "${rel.COLUMN_NAME} => ${rel.REFERENCED_COLUMN_NAME}"\n`;
    });

    this.mermaidDefinition = definition;
    this.loaded = true;
    setTimeout(() => {
      this.renderMermaid();
    });
  }

  renderMermaid(): void {
    mermaid.initialize({ startOnLoad: false });
    const element = document.querySelector('#mermaid-diagram');
    if (element) {
      mermaid.init(undefined, element as HTMLElement);
      setTimeout(() => {
        this.addClickEventListeners();
      }, 1000);
    }
  }

  addClickEventListeners(): void {
    const nodes = document.querySelectorAll('#mermaid-diagram g');
    nodes.forEach(node => {
      const tableName = this.extractTableNameFromNode(node);
      node.addEventListener('click', () => this.handleNodeClick(tableName));
    });
  }

  extractTableNameFromNode(node: Element): string {
    const textElement = node.querySelector('.er.entityLabel');
    if (textElement) {
      return textElement.textContent.trim();
    } else {
      return '';
    }
  }

  handleNodeClick(node): void {
    console.log('Node clicked:', node);
    let idFounded = false;

    // Aseguramos que exista la estructura de paramControlVariables
    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] =
        this.genericService.createNewParamVariable();
    }

    let output = this.genericService.paramControlVariables[
      this.structure[this.index]['id_functional_parent_initial']
    ];

    // Buscamos en el "output" si ya existe algo para "db_schemas_tables.bd_table"
    const indexParam = this.genericService.paramControlVariables[
      this.structure[this.index]['id_functional_parent_initial']
    ]['indexParam'];

    if (output['params'][indexParam] && output['params'][indexParam]['output']) {
      for (let j in output['params'][indexParam]['output']) {
        const item = output['params'][indexParam]['output'][j];
        if (
          item['id_db'] === 3 &&
          item['bd_table'] === 'db_schemas_tables' &&
          item['bd_field'] === 'bd_table'
        ) {
          item['value'] = node;
          idFounded = true;
          break;
        }
      }
    }

    // Si no estaba, lo añadimos
    if (!idFounded) {
      let obj = {
        id_db: 3,
        bd_table: 'db_schemas_tables',
        bd_field: 'bd_table',
        value: node
      };
      output['params'][indexParam]['output'].push(obj);
    }

    this.structure[this.index]['no_save_params'] = true;
    this.functionsControllerService.getFunction(this.structure[this.index]);
  }

  changeMyZoom() {
    setTimeout(() => {
      localStorage.setItem('myZoomDbSchema', JSON.stringify(this.myZoom));
    });
  }

  showAllColumnsFunction() {
    this.loaded = false;
    localStorage.setItem('showAllColumnsDbSchema', JSON.stringify(this.showAllColumns));
    setTimeout(() => {
      this.loadStructure();
    });
  }
}