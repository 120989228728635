import { Injectable } from "@angular/core";
import { GenericService } from "../services/generic/generic.service";
import { AuthService } from "../services/auth/auth.service";
import { RoutingService } from "../services/routing/routing.service";
import { ChatService } from "../services/chat.service";

@Injectable({
  providedIn: 'root', // O en algún módulo específico
})

export class ConditionsClientService {
  constructor(
    private genericService: GenericService,
    private authService: AuthService,
    private routingService: RoutingService,
    private chatService: ChatService
  ) { }


  public evaluateIfElements(parent, key, params = null): boolean {
    if (!parent || !parent['child'] || !parent['child'][key] || (!parent['child'][key]['id_functional_parent'] && !parent['child'][key]['creatingMode'])) return false;
    let structure = parent['child'][key];
    if(this.authService.checkUserIsDeveloping != 1) {
      if(this.genericService.deletedFaByUser[structure['id_functional_area']] !== undefined || this.genericService.deletedFaByUser[structure['id_functional_area_original']] !== undefined) {
        structure["deletedByIdUser"] = true;
      } else {
        structure["deletedByIdUser"] = false;
      }
      if(this.genericService.deletedFaByCompany[structure['id_functional_area']] !== undefined || this.genericService.deletedFaByCompany[structure['id_functional_area_original']] !== undefined) {
        structure["deletedByIdCompany"] = true;
      } else {
        structure["deletedByIdCompany"] = false;
      }
    }

    if(!this.genericService.editingModeByCompany && structure["deletedByIdCompany"]) return false;
    if(!this.genericService.editingModeByUser && structure["deletedByIdUser"]) return false;
    this.genericService.initElementInFrontend(structure, key, parent);
    if (structure["creatingMode"]) return true;
    if (!structure["initializedElement"]) return false;
    if (structure['id_functional_type'] === 106 && structure['id_functional_area'] != this.genericService.lastDeferContainer) return false;
    if (structure["id_functional_type"] === 12 && !(structure['id_query'] && this.genericService.selectOptions[structure['id_query']] && this.genericService.selectOptions[structure['id_query']].length && this.genericService.selectOptions[structure['id_query']].length > 0)) return false;
    if (structure["id_functional_type"] === 5 && structure["type"] === "menu" && !(structure["child"] && structure["child"][0] && structure["child"][0]["child"])) return false;
    return this.evaluateIfs(structure['id_condition'], structure['id_functional_area'], structure, false, params);
  }

  public evaluateIfs(ids: any, id_fa, structure, isBadge = false, params = null): boolean {
    if (ids && ids != "NULL" && ids !== null && ids != undefined) {
      if (isBadge) {
        return this.evaluateIfsCondition(ids, id_fa, structure, params);
      } else {
        if (this.evaluateIfsCondition(ids, id_fa, structure, params)) {
          if (structure["id_functional_status_general_precondition"] && structure["id_functional_status_general_precondition"] != 2) {
            structure["id_functional_status_general"] = structure["id_functional_status_general_precondition"];
          } else {
            if (structure["id_functional_status_general"] == 2) {
              structure["id_functional_status_general"] = 1;
            } else {
              structure["id_functional_status_general"] = structure["id_functional_status_general"];
            }
          }
          return true;
        } else {
          if (!structure["id_functional_status_general_precondition"] && structure["id_functional_status_general"] != 2) {
            structure["id_functional_status_general_precondition"] =
              structure["id_functional_status_general"];
          }
          structure["id_functional_status_general"] = 2;
          return false;
        }
      }
    } else {
      return true;
    }
  }

  
  public evaluateIfsCondition(ids: any, id_fa: any, structure: any, paramsElements: any) {
    let result = true;
    ids.toString();
    let allIds = JSON.parse("[" + ids + "]");
    let idFA = null;
    let idFA2 = null;

    for (const id of allIds) {
      id.toString();
      switch (id) {
        case 3:
          if (!this.routingService.shouldOpenChat) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 4:
          if (this.routingService.shouldOpenChat) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 5:
          if (!this.routingService.shouldOpenHelp) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 6:
          if (this.routingService.shouldOpenHelp) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 7:
          if (this.authService.accountsGeneric.length > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 8:
          if (
            this.authService.companyGenericName !== undefined &&
            this.authService.companyGenericName !== null
          ) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 9:
          if (this.chatService.non_read > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 10:
          result = false;
          if (structure["expansionStatus"] == 1) result = true;
          else if (structure["expansionStatus"] == 2) result = false;
          break;
        case 11:
          result = true;
          if (structure["expansionStatus"] == 1) result = true;
          else if (structure["expansionStatus"] == 2) result = false;
          break;
        case 12:
          if (!(this.authService.empresaId > 0)) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 13:
          if (this.authService.empresaId > 0) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 14:
          if (!structure["checkedCondition"]) {
            structure["checkedCondition"] = true;
            let idFa = this.genericService.findElementWithId(2351, false, false, true);
            let status = -1;
            if (idFa && idFa['tmp_value']) status = idFa['tmp_value'];
            if (status == 4) {
              structure["checkedCondition"] = true;
            } else structure["checkedCondition"] = false;
          }
          result = structure["checkedCondition"];
          break;
        case 15:
          if (structure["checkedCondition"] === undefined || structure["checkedCondition"] === null) {
            structure["checkedCondition"] = true;
            let idFa = this.genericService.findElementWithId(2351, false, false, true);
            let status = -1;
            if (idFa && idFa['tmp_value']) status = idFa['tmp_value'];
            if (status != 4) {
              structure["checkedCondition"] = true;
            } else structure["checkedCondition"] = false;
          }
          result = structure["checkedCondition"];
          break;
        case 16:
          let changes = this.genericService.getNumChanges();
          if (changes > 0) result = true;
          else result = false;
          break;
        case 17:
          if (this.authService.productsCounter > 0) result = true;
          else result = false;
          break;
        case 18:
          idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_servicios", "id_servicio", structure["id_functional_parent_initial_dsb"]);
          let query = this.genericService.selectOptions[idFA['id_query']];
          result = false;
          if (idFA['tmp_value'] && idFA['tmp_value'] !== undefined && idFA['tmp_value'] !== null && idFA['tmp_value'] != -1) {
            for (let i in query) {
              if (query[i]['value'] == idFA['tmp_value']) {
                result = query[i]['grupo'] == 1; // Es grupo
                break;
              }
            }
          }
          break;
        case 19:
          idFA = this.genericService.findFAWithTableField(1, "act_ludic_reservas_servicios", "id_servicio", structure["id_functional_parent_initial_dsb"]);
          let query2 = this.genericService.selectOptions[idFA['id_query']];
          result = false;
          if (idFA['tmp_value'] && idFA['tmp_value'] !== undefined && idFA['tmp_value'] !== null && idFA['tmp_value'] != -1) {
            for (let i in query2) {
              if (query2[i]['value'] == idFA['tmp_value']) {
                result = query2[i]['grupo'] != 1; // NO Es grupo
                break;
              }
            }
          }
          break;
        case 21:
          idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "id_creador", structure["id_functional_parent_initial_dsb"]);
          idFA2 = this.genericService.findFAWithTableField(1, "actividades_calendario", "id_creador_real", structure["id_functional_parent_initial_dsb"]);
          result =
            (idFA && idFA['tmp_value'] && idFA['tmp_value'] !== undefined && idFA['tmp_value'] !== null && idFA['tmp_value'] == this.authService.userId)
            || (idFA2 && idFA2['tmp_value'] && idFA2['tmp_value'] !== undefined && idFA2['tmp_value'] !== null && idFA2['tmp_value'] == this.authService.userId) || this.authService.modifica_actividades_otros;
          if (id == 21) result = !result;
          break;
        case 22:
          if (paramsElements !== null && paramsElements !== undefined && paramsElements['data'] !== null && paramsElements['idRow'] && paramsElements['data'] !== undefined && structure['condition_variable']) {
            let node = this.genericService.findElementWithId(structure['condition_variable'], false, false, true);
            if (node && paramsElements && paramsElements['data'] && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== undefined && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== null && (paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] > 0 || (paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']].length && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']].length > 0)) && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== "isbooleanfalse") {
              return true;
            }
            return false;
          } else return false;
          break;
        case 23:
          idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "id_creador", structure["id_functional_parent_initial_dsb"]);
          idFA2 = this.genericService.findFAWithTableField(1, "actividades_calendario", "id_creador_real", structure["id_functional_parent_initial_dsb"]);
          result =
            !(idFA && idFA['tmp_value'] && idFA['tmp_value'] !== undefined && idFA['tmp_value'] !== null)
            || !(idFA2 && idFA2['tmp_value'] && idFA2['tmp_value'] !== undefined && idFA2['tmp_value'] !== null)
            || idFA2['tmp_value'] == this.authService.userId;
          result = !result;
          break;
        case 24:
          if (!this.routingService.shouldOpenSearch) {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 25:
          let isDevLocal = localStorage.getItem("_in_dev_");
          if (this.authService.checkUserIsDeveloping == 1 || isDevLocal == "1") {
            result = result && true;
          } else {
            result = result && false;
          }
          break;
        case 26: // Opposite than conditon 22
          if (paramsElements !== null && paramsElements !== undefined && paramsElements['data'] !== null && paramsElements['idRow'] && paramsElements['data'] !== undefined && structure['condition_variable']) {
            let node = this.genericService.findElementWithId(structure['condition_variable'], false, false, true);
            if (node && node['id_functional_area'] && node['bd_field'] && paramsElements && paramsElements['data'] && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== undefined && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== null && (paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] > 0 || (paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']].length && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']].length > 0)) && paramsElements['data'][node['id_functional_area'] + '-' + node['bd_field']] !== "isbooleanfalse") {
              return false; 
            }
            return true;
          } else return true;
          break;
        case 27:
          idFA = this.genericService.findFAWithTableField(1, "hol_stock_movimientos_lotes", "cantidad", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
          idFA2 = this.genericService.findFAWithTableField(1, "hol_stock_movimientos_lotes", "comodin_cantidad_maxima", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
          
          if(idFA && idFA['tmp_value'] && idFA2 && idFA2['tmp_value'] && idFA['tmp_value'] > idFA2['tmp_value']) {
            result = false;
          }
          else result = true;
          break;
      }
    }
    return result;
  }


  evaluateIfsParams(parent, key, idTable, idRow, idTableIndex, param) {
    let params = this.genericService.sendParams(idTable, idRow, idTableIndex, param);
    let result = this.evaluateIfElements(parent, key, params)
    return result;
  }

  public checkChildActive(element, hideInitialOnly = false, changeStatus = true) {
    if (element['creatingMode']) return true;
    if (element['child']) {
      for (let i in element['child']) {
        if ((!element['child'][i]['initializedElement'] || this.evaluateIfElements(element, i)) && (element['child'][i]['id_functional_status_general'] != 2 || element['child'][i]['showSub']) && ((!hideInitialOnly && element['child'][i]['hide'] != 1) || (hideInitialOnly && element['child'][i]['hide_initial'] != 1)) || (element['child'][i]['id_functional_type'] == 12 && this.genericService.selectOptions[element['child'][i]['id_query']] && this.genericService.selectOptions[element['child'][i]['id_query']].length && this.genericService.selectOptions[element['child'][i]['id_query']] > 0)) {
          if (!hideInitialOnly) {
            if (element['id_functional_status_general_pre_checkChildActive']) element['id_functional_status_general'] = this.genericService.cloneVariable(element['id_functional_status_general_pre_checkChildActive']);
          }
          else {
            if (element['hide_pre_checkChildActive']) element['hide_frontend'] = this.genericService.cloneVariable(element['hide_pre_checkChildActive']);
          }
          return true;
        }
      }
    } else if (!changeStatus) {
      if (!hideInitialOnly) element['id_functional_status_general'] = this.genericService.cloneVariable(element['id_functional_status_general_pre_checkChildActive']);
      else element['hide_frontend'] = this.genericService.cloneVariable(element['hide_pre_checkChildActive']);
      return true;
    }
    if (hideInitialOnly) {
      if (element['hide_frontend'] === undefined) element['hide_frontend'] = 0;
      if (element['hide_pre_checkChildActive'] == undefined) element['hide_pre_checkChildActive'] = this.genericService.cloneVariable(element['hide_frontend']);
      element['hide_frontend'] = 1;
      return true;
    } else {
      if (element['id_functional_status_general_pre_checkChildActive'] == undefined) element['id_functional_status_general_pre_checkChildActive'] = this.genericService.cloneVariable(element['id_functional_status_initial']);
      element['id_functional_status_general'] = 2;
      return false;
    }
  }

  public checkChildActiveHideIntialOnly(element) {
    return true;
    return this.checkChildActive(element, true); // Solo es necesario para que los expansion no aparezcan
  }
}