import { Component, OnInit, Input } from '@angular/core';
import { FunctionsService } from '../../services/generic/functions.service';
import { GenericService } from '../../services/generic/generic.service';
import { RoutingService } from '../../services/routing/routing.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { AuthService } from 'app/services/auth/auth.service';

@Component({
  selector: 'app-menu-generic-element',
  templateUrl: './menu-generic-element.component.html',
  styleUrls: ['./menu-generic-element.component.scss']
})
export class MenuGenericElementComponent implements OnInit {

  constructor(
    public functionsService: FunctionsService,
    public functionsControllerService: FunctionsControllerService,
    public genericService: GenericService,
    public routingService: RoutingService,
    public authService: AuthService
  ) { }

  @Input() element = null;
  
  ngOnInit(): void {
    let show = this.element['id_type'] == 1 || this.element['id_parent'] == 0;
    if(this.element['children'] && !show) {
      for(let i in this.element['children']) {
        if(this.element['children'][i]['id_type'] == 1 || (this.element['children'][i]['id_type'] == 2 && (this.element['children'][i]['route'] == this.routingService.urlWithoutHashtagClean || (this.element['children'][i]['children'][0] && this.element['children'][i]['children'][0]['show']))) || (this.element['children'][i]['id_type'] == 3 && this.element['children'][i]['route'] == this.routingService.urlWithoutHashtagClean)) {
          show = true;
          break;
        }
      }
    }
    if(this.element['show'] === undefined) this.element['show'] = show;
  }

  goRoute(event) {
    if(this.element['route']) {
      if(event !== undefined && (event.ctrlKey || event.metaKey)) {
        this.functionsService.openInExteranlURL(this.element['route']);
      } else {
        this.functionsService.goRouting(this.element['route'], true);
        this.element['show'] = true;
      }
    } else this.changeHideELement();
  }

  changeHideELement() {
    this.element['show'] = !this.element['show'];
  }

  clickMenu(event, element) {
    if(element['id_type'] == 5) {
      let structure = {internal_routing_id_functional_area: element['id_functional_area'], id_functional_parent_initial: 89};
      this.functionsService.openDialog(structure, 1);
    } else {
      this.goRoute(event)
    }
  }
  
  swipeTabIndex(index, id_pantalla, route, event) {
    let response = this.genericService.swipeTabIndex(index, id_pantalla, route);
    if (response == 2) this.genericService.openSnackBar("La pantalla aún no se ha cargado completamente", 7000, ["red-snackbar"]);
    else if (response == 1) {
      let x = { id_function: 1, internal_routing: route, id_functional_parent_initial: this.routingService.moduleId };
      x['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
      this.functionsControllerService.getFunction(x, null);
    }
  }

  deleteIndexTabGeneric(id_pantalla, index) {
    this.genericService.isReloadingTabsMenu = id_pantalla == this.routingService.moduleId;
    setTimeout(() => {
      let pcv = this.genericService.paramControlVariables[id_pantalla];
      pcv['params'].splice(index, 1);
  
      // Corrimiento manual en paramControlVariablesFAs
      let objFAs = this.genericService.cloneVariable(this.genericService.paramControlVariablesFAs[id_pantalla]);
  
      // Borramos la clave actual
      delete objFAs[index];
  
      // Empezamos desde 'index' y vamos corriendo
      const keys = Object.keys(objFAs)              // ["0","1","3","4"]
      .map(k => +k)                               // [0,1,3,4]
      .sort((a, b) => a - b);                     // [0,1,3,4]
    
      // Buscas la posición de `index` en el array de claves
      const pos = keys.indexOf(index);
      if (pos !== -1) {
        // Quitas esa clave
        keys.splice(pos, 1);
      }
      
      // Reconstruyes el objeto
      const newObj = {};
      keys.forEach((oldKey, idx) => {
        newObj[idx] = objFAs[oldKey];
      });
      
      // Asignas de vuelta
      objFAs = newObj;

      this.genericService.paramControlVariablesFAs[id_pantalla] = objFAs;
  
      // También borramos en arrayFunctionalParentsLoaded
      this.genericService.arrayFunctionalParentsLoaded[id_pantalla].splice(index, 1);
  
      // Ajustamos indexParam si hace falta
      if (+pcv['indexParam'] > +index) {
        pcv['indexParam'] = +pcv['indexParam'] - 1;
      }

      // console.log('deleteIndexTabGeneric', pcv, objFAs, this.genericService.isReloadingTabsMenu);
      
      // Guardamos cambios
      this.genericService.updateParamControl();
    });
  }
}