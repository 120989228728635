<div *ngIf="this.elements.length > 0 && loadedMenus" class="my-menu-init">
    <span *ngIf="expandAllMenusBool" (click)="this.expandAllMenus(false)" class="icons-all-menu expand-all-menu" matTooltip="Cerrar menú">
        <span class="material-icons">keyboard_arrow_up</span>
        <span class="icons-all-menu-text">Cerrar menú</span>
    </span>
    <span *ngIf="!expandAllMenusBool" (click)="this.expandAllMenus(true)" class="icons-all-menu expand-all-menu" matTooltip="Abrir menú">
        <span class="material-icons">keyboard_arrow_down</span>
        <span class="icons-all-menu-text">Abrir menú</span>
    </span>
    <span (click)="this.closeAllMenus()" class="icons-all-menu close-all-menu" matTooltip="Cerrará todos los submenús que son fichas">
        <span class="material-icons">cancel</span>
        <span class="icons-all-menu-text">Limpiar fichas menú</span>
    </span>
    <app-menu-generic-elements [elements]="elements"></app-menu-generic-elements>
</div>