import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-timer-count',
  templateUrl: './timer-count.component.html',
  styleUrls: ['./timer-count.component.scss']
})
export class TimerCountComponent implements OnInit, OnDestroy {
  @Input() structure: any;
  @Input() index: number;

  years: number = 0;
  months: number = 0;
  days: number = 0;
  hours: number = 0;
  minutes: number = 0;
  seconds: number = 0;

  initTime: Date;
  counter: any = null;
  type: string = '';

  constructor(
    public functionsService: FunctionsService,
    public genericService: GenericService
  ) {}

  ngOnInit(): void {
    this.type = this.structure[this.index].type;
    this.initTime = this.parseDateFromLabel(this.structure[this.index].label);
    this.updateTime();
  }

  ngOnDestroy(): void {
    if (this.counter) {
      clearInterval(this.counter);
    }
  }

  /**
   * Convierte el string "dd-mm-yyyy, HH:MM" en un objeto Date
   */
  parseDateFromLabel(label: string): Date {
    // Si no tenemos string o no contiene ", "
    if (!label || !label.includes(', ')) {
      // Devuelve una fecha por defecto o lanza error
      console.warn('Label inválido. Se devuelve la fecha actual por defecto.');
      return new Date();
    }
  
    const [datePart, timePart] = label.split(', ');
    const [day, month, year] = datePart.split('-').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    
    return new Date(year, month - 1, day, hours, minutes);
  }
  

  /**
   * Retorna 'singular' si value es 1, 'plural' en caso contrario.
   */
  pluralize(value: number, singular: string, plural: string): string {
    return value === 1 ? singular : plural;
  }

  /**
   * Formatea un número a dos dígitos (por ejemplo, 5 => "05")
   */
  twoDigits(value: number): string {
    return value < 10 ? '0' + value : String(value);
  }

  updateTime(): void {
    if (this.type === 'counter') {
      // Cada minuto
      this.updateCounter();
      this.counter = setInterval(() => {
        this.updateCounter();
      }, 60000);
    } else if (this.type === 'countdown') {
      // Cada segundo
      this.updateCountdown();
      this.counter = setInterval(() => {
        this.updateCountdown();
        if (
          this.years <= 0 &&
          this.months <= 0 &&
          this.days <= 0 &&
          this.hours <= 0 &&
          this.minutes <= 0 &&
          this.seconds <= 0
        ) {
          clearInterval(this.counter);
        }
      }, 1000);
    }
  }

  /**
   * Calcula la diferencia estilo calendario entre dateA y dateB
   * Retorna años, meses, días, horas, minutos, segundos
   */
  getDateComponentsDiff(dateA: Date, dateB: Date) {
    let start = new Date(dateA.getTime());
    let end = new Date(dateB.getTime());

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();
    let days = end.getDate() - start.getDate();
    let hours = end.getHours() - start.getHours();
    let minutes = end.getMinutes() - start.getMinutes();
    let seconds = end.getSeconds() - start.getSeconds();

    // Ajuste de segundos
    if (seconds < 0) {
      seconds += 60;
      minutes--;
    }
    // Ajuste de minutos
    if (minutes < 0) {
      minutes += 60;
      hours--;
    }
    // Ajuste de horas
    if (hours < 0) {
      hours += 24;
      days--;
    }
    // Ajuste de días (restar un mes si days < 0)
    if (days < 0) {
      let prevMonth = end.getMonth() - 1;
      let prevYear = end.getFullYear();

      if (prevMonth < 0) {
        prevMonth += 12;
        prevYear--;
      }
      let daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate();
      days += daysInPrevMonth;
      months--;
    }
    // Ajuste de meses
    if (months < 0) {
      months += 12;
      years--;
    }

    return { years, months, days, hours, minutes, seconds };
  }

  /**
   * Tiempo transcurrido desde initTime hasta ahora
   */
  updateCounter(): void {
    let now = new Date();
    let diff = this.getDateComponentsDiff(this.initTime, now);

    this.years = diff.years;
    this.months = diff.months;
    this.days = diff.days;
    this.hours = diff.hours;
    this.minutes = diff.minutes;
    this.seconds = diff.seconds;
  }

  /**
   * Tiempo faltante desde ahora hasta initTime
   */
  updateCountdown(): void {
    let now = new Date();
    let diff = this.getDateComponentsDiff(now, this.initTime);

    // Si ya pasó
    if (now >= this.initTime) {
      this.years = 0;
      this.months = 0;
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      return;
    }

    this.years = diff.years;
    this.months = diff.months;
    this.days = diff.days;
    this.hours = diff.hours;
    this.minutes = diff.minutes;
    this.seconds = diff.seconds;
  }
}
