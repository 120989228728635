import { Injectable } from '@angular/core';

// If you've declared AutoScript in a .d.ts file, TypeScript will know its shape.
// Otherwise, add: declare var AutoScript: any; 
declare var AutoScript: any;

/**
 * A service that wraps the global AutoScript object.
 * You can add more methods if needed (e.g., saveDataToFile, getBase64FromText, etc.).
 */
@Injectable({
  providedIn: 'root'
})
export class AutoScriptService {

  constructor() { }

  /**
   * Invokes AutoScript to open the certificate selection dialog.
   * @param params Parameters for certificate selection.
   * @returns A Promise that resolves with the selected certificate (Base64) or rejects with an error.
   */
  selectCertificate(params: any): Promise<string> {
    return new Promise((resolve, reject) => {
      if (typeof AutoScript === 'undefined') {
        return reject('AutoScript is not defined. Make sure autoscript.js is loaded.');
      }
      console.log('AutoScript is:', AutoScript);
      AutoScript.selectCertificate(
        params,
        (certificateB64: string) => {
          resolve(certificateB64);
        },
        (errorType: string, errorMessage: string) => {
          reject(`${errorType}: ${errorMessage}`);
        }
      );
    });
  }

  /**
   * Invokes AutoScript to sign data in Base64 format.
   * @param dataB64 Data to be signed (in Base64).
   * @param algorithm The signing algorithm (e.g. "SHA512withRSA").
   * @param format The signing format (e.g. "FacturaE", "XAdES", etc.).
   * @param params Additional parameters (like callback URLs, extraParams, etc.).
   * @returns A Promise resolving with an object containing the signature and certificate, or rejecting with an error.
   */
  sign(
    dataB64: string | null,
    algorithm: string,
    format: string,
    params: any
  ): Promise<{ signatureB64: string; certB64: string; fileName?: string }> {
    return new Promise((resolve, reject) => {
      if (typeof AutoScript === 'undefined') {
        return reject('AutoScript is not defined. Make sure autoscript.js is loaded.');
      }

      AutoScript.sign(
        dataB64,
        algorithm,
        format,
        params,
        (signatureB64: string, certB64: string, fileName?: string) => {
          resolve({ signatureB64, certB64, fileName });
        },
        (errorType: string, errorMessage: string) => {
          reject(`${errorType}: ${errorMessage}`);
        }
      );
    });

    
  }
  /**
   * Initializes the AutoFirma client by invoking the cargarAppAfirma function.
   * @param clientAddress The address of the signing client.
   * @param keystore Optional keystore value; if not provided, a default will be used.
   * @param avoidJnlpLoad Optional flag to avoid loading JNLP.
   */
  initializeClient(clientAddress?: string, keystore?: string, avoidJnlpLoad?: boolean): void {
    if (typeof AutoScript === 'undefined') {
      console.error('AutoScript is not defined. Make sure autoscript.js is loaded.');
      return;
    }
    AutoScript.cargarAppAfirma(clientAddress, keystore, avoidJnlpLoad);
  }
}
