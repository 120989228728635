import { Component, OnInit, Input } from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { EndpointRustService } from 'app/services/generic/endpoint-rust.service';

@Component({
  selector: 'app-table-graphs',
  templateUrl: './table-graphs.component.html',
  styleUrls: ['./table-graphs.component.scss']
})
export class TableGraphsComponent implements OnInit {
  @Input() structure = null;
  @Input() index: number;
  @Input() indexTable: number;
  @Input() group: any;
  @Input() oldBdField: any;
  @Input() oldValue: any;
  @Input() element: any;

  public chartOptions = {};
  public loaded = false;
  public dataGraph = [];
  public labelChart = "";

  constructor(
    public genericService: GenericService,
    private endpointService: EndpointService,
    private endpointRustService: EndpointRustService,
  ) { }

  ngOnInit(): void {
    console.log('TableGraphsComponent', this.element, this.structure, this.index, this.indexTable, this.group, this.oldBdField, this.oldValue);
    if(!this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']]) this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']] = {};
    if(!this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable]) this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable] = {};
    console.log('TableGraphsComponent 2', this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']]);
    if(!this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']]) this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']] = {};
    if(this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable] && this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable] && this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']].dataGraph) {
      this.executeGraph(this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']]);
    } else {
      // 1) Obtenemos todos los datos filtrados (RAW)
      if(this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].dataSourceTables[this.indexTable]['filteredData'].length == 0) {
        this.labelChart = this.element['label'] || this.element['suffix'] || "No data";
        this.loaded = true;
      } else {
        setTimeout(() => {
          this.dataGraph = this.genericService.cloneVariable(
            this.genericService.tablesInformation[this.structure[this.index]['id_functional_area']].dataSourceTables[this.indexTable]['filteredData']
          );
    
          // 2) Mandamos al backend para que haga la lógica de agregación
          this.sendDataToBackend();
        });
      }
    }
  }

  /**
   * Envía la data raw al backend PHP para que la procese
   * y devuelva el dataGraph ya agregado y chartOptions.
   */
  private sendDataToBackend() {
    // 1) Obtenemos los identificadores de columna
    const xField         = this.findColumnFromId(this.element['id_functional_area_x']) || "";
    let fieldX           = this.genericService.findElementWithId(this.element['id_functional_area_x'], false, true, true);
    let xFieldPrefix     = "";
    let xFieldSuffix     = "";
    if (fieldX) {
      xFieldPrefix = fieldX['text'] || "";
      xFieldSuffix = fieldX['hint'] || "";
    }

    const xFieldBis         = this.findColumnFromId(this.element['id_functional_area_x_bis']) || "";
    let fieldXBis           = this.genericService.findElementWithId(this.element['id_functional_area_x_bis'], false, true, true);
    let xFieldBisPrefix     = "";
    let xFieldBisSuffix     = "";
    if (fieldXBis) {
      xFieldBisPrefix = fieldXBis['text'] || "";
      xFieldBisSuffix = fieldXBis['hint'] || "";
    }
    
    const yField         = this.findColumnFromId(this.element['id_functional_area_y']) || "";
    let fieldY           = this.genericService.findElementWithId(this.element['id_functional_area_y'], false, true, true);
    let yFieldPrefix     = "";
    let yFieldSuffix     = "";
    if (fieldY) {
      yFieldPrefix = fieldY['text'] || "";
      yFieldSuffix = fieldY['hint'] || "";
    }
    
    const groupByField         = this.findColumnFromId(this.element['id_functional_area_group_by']) || "";
    let fieldGroupBy           = this.genericService.findElementWithId(this.element['id_functional_area_group_by'], false, true, true);
    let groupByFieldPrefix     = "";
    let groupByFieldSuffix     = "";
    if (fieldGroupBy) {
      groupByFieldPrefix = fieldGroupBy['text'] || "";
      groupByFieldSuffix = fieldGroupBy['hint'] || "";
    }
  
    // 2) "Filtramos" el array original quedándonos sólo con xField, xFieldBis, yField y groupByField en cada objeto
    let filteredData = this.dataGraph.map(item => {
      const minObj = {};
      // Sólo asignamos si existen
      if (xField && item[xField] !== undefined) {
        minObj[xField] = item[xField];
      }
      if (xFieldBis && item[xFieldBis] !== undefined) {
        minObj[xFieldBis] = item[xFieldBis];
      }
      if (yField && item[yField] !== undefined) {
        minObj[yField] = item[yField];
      }
      if (groupByField && item[groupByField] !== undefined) {
        minObj[groupByField] = item[groupByField];
      }
      return minObj;
    });

    // 3) Construimos el body para el backend
    const body = {
      chartType:           this.element['id_type'],            
      idTypeDate:          this.element['id_type_date'],       
      showTotal:           this.element['show_total_graphs'],  
      suffix:              this.element['suffix'] || "",
      label:               this.element['label'] || "",
      xField:              xField,
      xFieldBis:           xFieldBis,
      yField:              yField,
      groupByField:        groupByField,
      xFieldPrefix:        xFieldPrefix,
      xFieldBisPrefix:     xFieldBisPrefix,
      yFieldPrefix:        yFieldPrefix,
      groupByFieldPrefix:  groupByFieldPrefix,
      xFieldSuffix:        xFieldSuffix,
      xFieldBisSuffix:     xFieldBisSuffix,
      yFieldSuffix:        yFieldSuffix,
      groupByFieldSuffix:  groupByFieldSuffix,
  
      // <-- Usamos filteredData en lugar del array completo
      rawData:        filteredData 
    };
  
    // 4) Llamada al servicio que consume tu API Rust (o PHP)
    // this.endpointService.loadTableDisplays(body).subscribe(
    this.endpointRustService.loadTableDisplays(body).subscribe(
      data => {
        this.genericService.tablesInformationGraphs[this.structure[this.index]['id_functional_area']][this.indexTable][this.element['id_element']] = data['response'];
        const response = data['response'];
        this.executeGraph(response);
      },
      error => {
        console.error('Error procesando gráfico en backend:', error);
        // Manejo de error...
      }
    );
  }

  /**
   * findColumnFromId: busca la columna en displayedColumns
   */
  private findColumnFromId(id: string): string {
    if (!this.structure || !this.structure[this.index]) return "";
    for (let col of this.structure[this.index]['displayedColumns']) {
      if(col == "0000-selectorCheckbox") continue;
      if (!Array.isArray(col) && col.split('-')[0] == id) return col;
    }
    return "";
  }

  executeGraph(response: any) {
    this.dataGraph = response.dataGraph;
    this.chartOptions = response.chartOptions;
    this.labelChart = response.labelChart;
    setTimeout(() => {
      this.loaded = true;
    });
  }
}