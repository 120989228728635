import {AfterContentChecked, Component, Input, OnInit, ChangeDetectorRef, OnDestroy, ViewChild} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import { FunctionsService } from 'app/services/generic/functions.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { GenericService } from 'app/services/generic/generic.service';
import { Subscription } from "rxjs";
import {DatePipe, formatDate} from "@angular/common";
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatCalendar } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';
import { min } from 'date-fns';
import { TwoDecimalDirective } from './two-decimal.directive';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class InputComponent implements OnInit, AfterContentChecked, OnDestroy {

  @Input() index : number;
  @Input() structure = null;
  genericForm : UntypedFormGroup;
  @ViewChild('calendar') calendar: MatCalendar<any>;
  @ViewChild('timeInput') timeInput: any;

  public placeHolder : string;
  public bdField : string;
  public hide = true;
  public value : string;
  //public idQuery : number;
  public date : any;
  public multiple : boolean;
  public selectable : boolean;
  public removable : boolean;
  public addOnBlur : boolean;
  public separatorKeysCodes: number[];
  public chipsList : any[] = [];
  public maxLength = 0;
  public startTime = '09:00';
  public endTime = '17:00';
  public maxLengthDecimal = "X,YY";
  private formSubscription: Subscription;
  private structureSubscription : Subscription;
  private formFieldsSubscription: Subscription;
  private vcfcsbcr: Subscription;
  public focused = false;
  public valuePw;
  public initialValue = null;
  public startViewDatepicker = 'month';
  public minValue = -999999999999999999999;
  public maxValue = 999999999999999999999;

  public class : string;

  public notOwner = false;
  public firstTime = true;
  public timeOptions: string[] = ['10:00', '10:15', '10:30', '10:45', '11:00', '11:15'];
  public dateClass: MatCalendarCellClassFunction<Date>;


  constructor(private changeDetector: ChangeDetectorRef,
    private functionsService: FunctionsService,
    private functionsControllerService: FunctionsControllerService,
    public conditionsClientService: ConditionsClientService,
    public genericService: GenericService,
    public datepipe: DatePipe,
    private adapter: DateAdapter<any>
    ) {}

  ngAfterContentChecked() {
    if(!this.structure[this.index]['creatingMode']) {
      this.changeDetector.detectChanges();
    }
  }
  
  ngOnInit(): void {
    this.genericForm = this.structure[this.index]['form'];
    this.bdField = this.structure[this.index]['bd_field'];

    //this.setFunctionalValue();
    if(this.structure[this.index]['min_value'] != undefined && this.structure[this.index]['min_value'] != null && this.structure[this.index]['min_value'] != "") {
      this.minValue = +this.structure[this.index]['min_value'];
    }
    if(this.structure[this.index]['max_value'] != undefined && this.structure[this.index]['max_value'] != null && this.structure[this.index]['max_value'] != "") {
      this.maxValue = +this.structure[this.index]['max_value'];
    }

    this.genericService.initFormChangeField(this.structure[this.index]);
    if(this.structure[this.index]['type'] === 'datepicker-month') {
      this.startViewDatepicker = "year";
    } else if(this.structure[this.index]['type'] === 'datepicker-year') {
      this.startViewDatepicker = "multi-year";
    }
    if(this.genericForm && this.genericForm.value) this.date = this.genericForm.value[this.index['bd_field']];
    this.multiple = this.structure[this.index]['multiple'] == 1;
    this.maxLength = this.structure[this.index]['max_length'] !== null ? this.structure[this.index]['max_length'] : 524288
    if(this.maxLength == null || this.maxLength == 0) this.maxLength = 524288;
    if(this.structure[this.index]['type'] == "decimal") {
      this.maxLengthDecimal = new Array(this.maxLength + 1).join("x");
      this.maxLengthDecimal += ",yy"
      this.maxLength += 3;
    }
    this.selectable = true;
    this.removable = true;
    this.addOnBlur = true;
    this.separatorKeysCodes = [ENTER, COMMA];
    this.structure[this.index].starsOnHoverValue = -1;

    if((!this.structure[this.index]['tmp_value'] && this.structure[this.index]['tmp_value'] !== 0)
    || this.structure[this.index]['tmp_value'] === null || this.structure[this.index]['tmp_value'] === undefined) {
      this.structure[this.index]['tmp_value'] = '';
    }

    if(this.structure[this.index]['tmp_value'] && this.structure[this.index]['type'] == 'datetime-local') {
      this.structure[this.index]['tmp_value'] = this.structure[this.index]['tmp_value'].slice(0, -2) + "00";
    }
    
    this.value = this.structure[this.index]['tmp_value'];
    if(this.structure[this.index]['tmp_value_init'] === undefined) this.structure[this.index]['tmp_value_init'] = this.functionsService.cloneVariable(this.structure[this.index]['tmp_value']);
    this.initialValue = this.structure[this.index]['tmp_value_init'];
    if(this.value == '???' && this.structure[this.index]['type'] != 'text'){
      this.value = '000000000';
      this.notOwner = true;
    }
    if(this.structure[this.index]['type'] == 'datepicker' || this.structure[this.index]['type'] == 'datepicker-month' || this.structure[this.index]['type'] == 'datepicker-year' || this.structure[this.index]['type'] == 'calendarpicker') {
      this.value = this.functionsService.formatDate(this.value, false);
    }
    if(this.structure[this.index]['type'] == 'password') {
      if(this.value !== null && this.value != '') {
        this.valuePw = JSON.parse(JSON.stringify(this.value));
        this.structure[this.index]['form_field'] = 0;
        this.structure[this.index].hasTmpValuePw = true;
      }
    }
    if(this.structure[this.index]['type'] == '5-stars') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false]
    }
    if(this.structure[this.index]['type'] == '10-stars') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false, false, false, false, false, false]
    }
    if(this.structure[this.index]['type'] == '3-faces') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false]
      this.structure[this.index].iconFacesArray = this.genericService.iconFacesArray3;
    }
    if(this.structure[this.index]['type'] == '5-faces') {
      this.structure[this.index].starsOnHoverValue = this.structure[this.index]['tmp_value'] ? this.structure[this.index]['tmp_value'] -1 : -1;
      this.structure[this.index].starsArray = [false, false, false, false, false];
      this.structure[this.index].iconFacesArray = this.genericService.iconFacesArray5;
    }
    for(let k in this.structure[this.index].starsArray) {
      if(+k <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[k] = true;
      else this.structure[this.index].starsArray[k] = false;
    }
    if(this.multiple) {
      if (!Array.isArray(this.structure[this.index]['tmp_value'])) this.structure[this.index]['tmp_value'] = [];
      this.structure[this.index].originalValue = JSON.parse(JSON.stringify(this.structure[this.index]['tmp_value']))
      for(let i in this.structure[this.index]['tmp_value']) {
        this.chipsList.push({name: this.structure[this.index]['tmp_value'][i].toString().trim()});
      }
    }

    if (this.value !== undefined && this.value !== "" && this.value !== null) {
      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
      });
    }
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    if(this.genericForm && this.genericForm['controls'] && this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.bdField]) {
      this.formSubscription = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.bdField].valueChanges.subscribe(newToogleValue=> {
        this.firstTime = false;
        this.genericForm.value[this.index['bd_field']] = this.date;
        let vnew = newToogleValue;
        this.value = newToogleValue;
        let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
        if(id_parent == undefined || id_parent == null) id_parent = this.structure[this.index]['id_functional_parent'];
        if(this.structure[this.index]['type'] === "datepicker" || this.structure[this.index]['type'] === "datepicker-month" || this.structure[this.index]['type'] === "datepicker-year") {
          vnew = this.functionsService.formatDate(vnew, false);
          vnew = this.functionsService.cloneVariable(vnew).split(" ")[0];
        }
        let vold = this.initialValue;
        let type = "";
        if((this.structure[this.index]['type'] == 'number' || this.structure[this.index]['type'] == 'number-buttons') && !this.multiple && vnew !== undefined && vnew !== null && vnew !== "" ) {
          if(this.maxLength && this.maxLength > 0 && vnew.length && vnew.length > this.maxLength) {
            vnew = vnew.slice(0, this.maxLength);
            this.value = this.genericService.cloneVariable(vnew);
            this.genericService.assignValueFunctionalArea(this.structure[this.index], this.genericService.cloneVariable(vnew));
            return;
          }
          if (vnew < this.minValue) {
            this.value = this.genericService.cloneVariable(this.minValue);
            this.genericService.assignValueFunctionalArea(this.structure[this.index], this.genericService.cloneVariable(this.minValue));
            return;
          } else if (vnew > this.maxValue) {
            this.value = this.genericService.cloneVariable(this.maxValue);
            this.genericService.assignValueFunctionalArea(this.structure[this.index], this.genericService.cloneVariable(this.maxValue));
            return;
          }
        }
        if(this.structure[this.index]['type'] == 'password') type = 'password_';
        else if(this.structure[this.index]['type'] == '5-stars') type = '5-stars_';
        else if(this.structure[this.index]['type'] == '10-stars') type = '10-stars_';
        else if(this.structure[this.index]['type'] == '3-faces') type = '3-faces_';
        else if(this.structure[this.index]['type'] == '5-faces') type = '5-faces_';
        else type = 'input_';
        if(vold && (this.structure[this.index]['type'] == "datepicker" || this.structure[this.index]['type'] === "datepicker-month" || this.structure[this.index]['type'] === "datepicker-year")) {
          vold = this.functionsService.formatDate(vold, false);
          vold = this.functionsService.cloneVariable(vold).split(" ")[0];
        }
        this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], type, vold, vnew, vold, vnew, this.structure[this.index]['label']);
        // this.genericService.updateStatus(data_validity_value, this.structure[this.index])
        if(!this.multiple) this.structure[this.index]['tmp_value'] = vnew;
        if(this.initialValue != vnew){
          if(!this.genericService.formsChanged[parent] && !(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0)) {
            this.genericService.formsChanged[parent] = {};
            this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']] = new Map();
          }
        } else {
          if(this.structure[this.index]['id_functional_parent_initial_dsb'] > 0) parent = this.structure[this.index]['id_functional_parent_initial_dsb'];
          if(this.genericService.formsChanged[parent] && this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']] && !this.firstTime){
            setTimeout(() => {
              delete this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][type + this.structure[this.index]['id_functional_area']];
            }, 500);
          }
        }
      });

      this.vcfcsbcr = this.genericForm['controls'][this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']].valueChanges.subscribe((newValue) => {
        this.updateDataValidity(newValue);
      })
    }

    this.structureSubscription = this.genericService.structureUpdated.subscribe(
      (strc) => {
        for(let key in strc){
          const elem = this.genericService.findElementWithIdLoop(strc[key], this.structure[this.index]['id_functional_area']);
          if(elem){
            this.value = elem['child']['tmp_value'];
            if (this.value) {
              this.genericForm.patchValue({
                [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.value
              });
            }
          }
        }
      }
    );

    this.formFieldsSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.value = this.structure[this.index]['tmp_value'];
    })
  }

  ngAfterViewInit() {
    this.updateDataValidity(this.structure[this.index]["tmp_value"]);
    this.functionsControllerService.getFunctionFormField(this.structure[this.index], true);
  }

  ngOnDestroy() {
    this.genericService.deleteRequiredInFrom(this.structure[this.index]);
    if(this.formSubscription) this.formSubscription.unsubscribe()
    if(this.structureSubscription) this.structureSubscription.unsubscribe()
    if(this.formFieldsSubscription) this.formFieldsSubscription.unsubscribe()
    if(this.vcfcsbcr) this.vcfcsbcr.unsubscribe()
  }

  setFunctionalValue() : void {
    let element;
    (async() => {
      while(typeof this.structure[this.index]['functional_value'] === 'undefined') {
        await new Promise(resolve => setTimeout(resolve, 200));
      }
      element = document.getElementById(this.bdField);
      element.value = this.structure[this.index]['functional_value'];
      this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + this.bdField] = this.structure[this.index]['functional_value'];
    })();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.chipsList.push({name: value.trim()});
      this.structure[this.index]['tmp_value'].push(value.trim())
    }
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.structure[this.index]['tmp_value']
    });


    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(chip: any): void {
    const index = this.chipsList.indexOf(chip);
    if (index >= 0) {
      this.chipsList.splice(index, 1);
    }
    for(let i in this.structure[this.index]['tmp_value']) {
      if(this.structure[this.index]['tmp_value'][i] === chip.name) {
        this.structure[this.index]['tmp_value'].splice(i, 1);
        break;
      }
    }
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: this.structure[this.index]['tmp_value']
    });
  }

  clear(field){
    this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field] = '';
    this.value = '';
    if(!this.multiple)this.structure[this.index]['tmp_value'] = '';
    else this.structure[this.index]['tmp_value'] = [];
    let element = <HTMLInputElement>document.getElementById(this.structure[this.index]['id_functional_area'] + '-' + field);
    element.value = '';
    this.structure[this.index]['form']['controls'][this.structure[this.index]['id_functional_area'] + '-' + field].setValue('');
    this.structure[this.index].starsOnHoverValue = -1;
    let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
    let vnew = '';
    let vold = this.initialValue;
    let parent = this.structure[this.index]['id_functional_parent_initial'];
    if(id_parent == undefined || id_parent == null) id_parent = this.structure[this.index]['id_functional_parent'];
    if(this.structure[this.index]['type'] == 'password') this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'password_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '5-stars') this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '5-stars_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '10-stars') this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '10-stars_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '3-faces') this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '3-faces_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else if(this.structure[this.index]['type'] == '5-faces') this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], '5-faces_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    else this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'input_', vold, vnew, vold, vnew, this.structure[this.index]['label']);

    for(let k in this.structure[this.index].starsArray) {
      if(+k <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[k] = true;
      else this.structure[this.index].starsArray[k] = false;
    }
  }

  addOneInput(hasToExecute, field, add = true) {
    if(hasToExecute) {
      let newValSum = +this.genericService.cloneVariable(this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field]);
      if(newValSum) {
        if(add) ++newValSum
        else --newValSum;
      } else {
        if(add) newValSum = +1;
        else newValSum = -1;
      }
      let newVal = newValSum.toString();
  
      this.genericForm.value[this.structure[this.index]['id_functional_area'] + '-' + field] = newVal;
      this.value = newVal;
      this.structure[this.index]['tmp_value'] = newVal;
      let element = <HTMLInputElement>document.getElementById(this.structure[this.index]['id_functional_area'] + '-' + field);
      element.value = newVal;
      this.structure[this.index]['form']['controls'][this.structure[this.index]['id_functional_area'] + '-' + field].setValue(newVal);
      this.structure[this.index].starsOnHoverValue = -1;
      
      // let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
      // let vnew = newVal;
      // let vold = this.initialValue;
      // let parent = this.structure[this.index]['id_functional_parent_initial'];
      // if(id_parent == undefined || id_parent == null) id_parent = this.structure[this.index]['id_functional_parent'];
      // this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'input_', vold, vnew, vold, vnew, this.structure[this.index]['label']);
    }
  }

  onClick(fa, event) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    this.functionsControllerService.getFunction(fa, );
  }

  removePw() {
    this.structure[this.index].hasTmpValuePw = false;
    this.structure[this.index]['form_field'] = 1;
    this.value = '';
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: ''
    });
    let id_parent = this.structure[this.index]['origin_duplicated_id_functional_area'];
    if(id_parent === undefined || id_parent === null) id_parent = this.structure[this.index]['id_functional_parent'];
    const parent = this.structure[this.index]['id_functional_parent_initial'];
    this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], parent, id_parent, this.structure[this.index]['id_functional_area'], 'password_', 'OLD', '-', "OLD", '-', this.structure[this.index]['label']);
  }

  mouseOverStars(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(star <= i) this.structure[this.index].starsArray[star]= true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  mouseOutStars(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(+star <= this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  clickIcon(i) {;
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    this.structure[this.index].starsOnHoverValue = i;
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.bdField]: i+1
    });
  }

  mouseOverFaces(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(star == i) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  mouseOutFaces(i) {
    if(this.structure[this.index]['id_functional_status_general'] === 3) return;
    for(let star in this.structure[this.index].starsArray) {
      if(+star == this.structure[this.index].starsOnHoverValue) this.structure[this.index].starsArray[star] = true;
      else this.structure[this.index].starsArray[star] = false;
    }
  }

  updateFormDateCalendar(value: any) {
    let selectedDate = value;
    if(value['_d']) selectedDate = value['_d'];
    const timezoneOffset = selectedDate.getTimezoneOffset() * 60000; // get timezone offset in milliseconds
    const timezoneAdjustedDate = new Date(selectedDate.getTime() - timezoneOffset); // adjust date based on timezone offset
    const formattedDate = formatDate(timezoneAdjustedDate, 'yyyy-MM-dd', 'en-US', 'America/New_York'); // format date with timezone
    this.genericForm.get(this.structure[this.index]['id_functional_area'] + '-' + this.bdField).setValue(formattedDate);
  }

  filterDates = (date: Date): boolean => {
    if(this.structure[this.index]['id_functional_status_general'] !== 3) {
      if(this.structure[this.index]['id_query'] > 0) {
        let data = this.genericService.selectOptions[this.structure[this.index]['id_query']];
        if (data !== undefined && date !== null && data.length && data.length > 0) {
          for(let i in data) {
            let date2 = new Date(date);
            if(data[i]['dias_semana']) {
              var dayOfWeek = date2.getDay();
              var weekdays = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
              let dias_semana_bool = Object.keys(JSON.parse(data[i]['dias_semana'])).filter(x=>x==weekdays[dayOfWeek]).length > 0;
              let dateTransform = this.functionsService.formatDate(date2).split(' ')[0];
              if(data[i]['date_ignore'] && data[i]['date_ignore'] !== undefined && data[i]['date_ignore'] !== null && dateTransform == data[i]['date_ignore']) return false; // Check if ya hay una reserva
              if(data[i]['date_ignore_2'] && data[i]['date_ignore_2'] !== undefined && data[i]['date_ignore_2'] !== null && dateTransform == data[i]['date_ignore_2']) return false; // Check if ya hay una reserva
              if((!data[i]['fecha_inicio'] || data[i]['fecha_inicio'] === null || dateTransform >= data[i]['fecha_inicio']) && (!data[i]['fecha_fin'] || data[i]['fecha_fin'] === null || dateTransform <= data[i]['fecha_fin']) && dias_semana_bool && dateTransform >= this.functionsService.formatDate(new Date()).split(' ')[0]) return true; // Check if hay disponibilidades
            }
          }
          return false;
        } else return false;
      } else return true;
    } else return false;
  }

  filterTimes = (timeT: string): boolean => {
    if(!timeT) {
      return false;
    }
    // Check if the time is within the specified range (startTime <= time <= endTime)
    let startTime = "00:00";
    let endTime = "23:59";
    if(this.structure[this.index]['id_query'] > 0) {
      let data = this.genericService.selectOptions[this.structure[this.index]['id_query']];
      if (data !== undefined && data.length > 0) {
        for(let i in data) {
          if(data[i]['inicio'] && data[i]['inicio'] !== undefined && data[i]['inicio'] !== null) startTime = data[i]['inicio'];
          if(data[i]['fin'] && data[i]['fin'] !== undefined && data[i]['fin'] !== null) endTime = data[i]['fin'];
        }
      }
    } else return false;
 
    const timeParts = timeT.split(':');
    const timeValue = new Date(2000, 0, 1, parseInt(timeParts[0]), parseInt(timeParts[1]));
    
    const startParts = startTime.split(':');
    const endParts = endTime.split(':');
    const startTimeValue = new Date(2000, 0, 1, parseInt(startParts[0]), parseInt(startParts[1]));
    const endTimeValue = new Date(2000, 0, 1, parseInt(endParts[0]), parseInt(endParts[1]));

    return timeValue >= startTimeValue && timeValue <= endTimeValue;
  };
  
  generateTimeOptions() {
    let startTime = "00:00";
    let endTime = "23:59";
    if(this.structure[this.index]['refreshing'] !== undefined && this.structure[this.index]['refreshing'] && this.timeInput && this.timeInput !== undefined ) {
      this.timeOptions = [];
      if(this.structure[this.index]['id_query'] > 0) {
        let data = this.genericService.selectOptions[this.structure[this.index]['id_query']];
        if (data !== undefined && data.length > 0) {
          for(let i in data) {
            if(data[i]['inicio'] && data[i]['inicio'] !== undefined && data[i]['inicio'] !== null) startTime = data[i]['inicio'];
            if(data[i]['fin'] && data[i]['fin'] !== undefined && data[i]['fin'] !== null) endTime = data[i]['fin'];
          }
        }
      }
      if (startTime != this.startTime || endTime != this.endTime) {
        const startTimeParts = startTime.split(':');
        const endTimeParts = endTime.split(':');

        const startHour = parseInt(startTimeParts[0]);
        const startMinute = parseInt(startTimeParts[1]);
        const endHour = parseInt(endTimeParts[0]);
        const endMinute = parseInt(endTimeParts[1]);

        // Initialize the date object with a common date (e.g., January 1, 2000)
        const currentDate = new Date(2000, 0, 1, startHour, startMinute);
        
        // Loop through the hours and minutes to generate the time options
        while (currentDate.getHours() < endHour || (currentDate.getHours() === endHour && currentDate.getMinutes() <= endMinute)) {
          const timeOption = `${this.formatTime(currentDate.getHours())}:${this.formatTime(currentDate.getMinutes())}`;
          this.timeOptions.push(timeOption);
          
          // Increment the minutes for the next iteration
          currentDate.setMinutes(currentDate.getMinutes() + 15); // Adjust this value as per your requirement
        }
        this.startTime = startTime;
        this.endTime = endTime;
      }
      this.structure[this.index]['refreshing'] = false;
      return true;
    }
    return true; 
  }

  // Method to format the time values with leading zeros
  formatTime(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  filterDates_OLD = (date: Date): boolean => {
    if(this.structure[this.index]['id_functional_status_general'] !== 3) {
      if(this.structure[this.index]['id_query'] > 0) {
        let data = this.genericService.selectOptions[this.structure[this.index]['id_query']];
        if (data !== undefined) {
          for(let i in data) {
            if(data[i]['value']) {
              let da = data[i]['value'].split(' ')[0];
              da = da.split(',')[0];
              let d = this.datepipe.transform(date, 'dd-MM-y');
              if(da.split('-')[0].length > 3) d = this.datepipe.transform(date, 'y-MM-dd');
              if(da == d) return true;
            } else return false;
          }
          return false;
        } else return true;
      } else return true;
    } else return false;
  }

  private updateDataValidity(v, init = false) {
    let data_validity_value = v;
    if (v === undefined || v === null || v === '') data_validity_value = -1;
    this.genericService.updateStatus(data_validity_value, this.structure[this.index])
  }

  public checkIfReferesh() {
    if((this.structure[this.index]['refreshing'] !== undefined && this.structure[this.index]['refreshing']) && this.calendar && this.calendar !== undefined) {
      this.dateClass = (date: Date): string[] => {
        const isDateEnabled = this.filterDates(date);
        return isDateEnabled ? ['enabled-date-class'] : ['disabled-date-class'];
      };
      this.structure[this.index]["refreshing"] = false;
      this.dateClass = (date: Date): string[] => {
        return this.filterDates(date) ? ['enabled-date-class'] : ['disabled-date-class'];
      };
      this.calendar.updateTodaysDate();
    }
    return true;
  }

  public validateHintLength() {
    return this.maxLength >= 1 && this.maxLength != 524288 && this.focused &&
            this.value !== undefined &&
            (
              this.maxLength - this.value.length <= 0.5*this.maxLength
              || this.maxLength - this.value.length <= 30
              || this.value.length == this.maxLength
            );
  }
}