import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/internal/operators";
import { Values } from "../../../values/values";
import { ApiChatService } from "../api-chat/api-chat.service";

@Injectable()
export class EndpointService {
  private URL = Values.SERVER_URL;

  private reqPattern = Values.API_REQ_PATTERN;

  private observable = null;
  //used for caching some backend requests

  // Joan private userInfoResponse = null;

  constructor(private http: HttpClient, private chatApi: ApiChatService) {}

  ////////////////////////////////////////////////////////////////////////////////

  public loginUser(user: string, password: string) {
    let body = JSON.stringify({ user: user, password: password });
    // let requestOptions = this.getRequestOptions();
    let requestURL = this.URL + Values.API_RESOURCES.LOGIN +
    this.reqPattern +
    Values.LOGIN_REQS.TRY_LOGIN;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public validateUser(user: string) {
    let body = JSON.stringify({ user: user });
    let requestURL = this.URL + Values.API_RESOURCES.LOGIN + this.reqPattern + Values.LOGIN_REQS.VALIDTE_USER;
    // let requestOptions = this.getRequestOptions();
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public uploadUserProfilePicture(formData, idUser, nameImage) {
    const headers = new HttpHeaders();

    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.UPLOAD_USER_PROFILE_PICTURE;

    requestURL += "&idUser=" + idUser;
    requestURL += "&nameImage=" + nameImage;

    //return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));

    return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public getUsers(idInmo: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.GET_USERS;
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getAllFromUser(idUser: number) {
    //can be string, when more than 1
    let idUserParam = "&idUser=";
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.GET_ALL_FROM_USER;
    requestURL += idUserParam + idUser;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public reloadQueries(allData) {
    let body = JSON.stringify({ allData });
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.RELOAD_QUERIES;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertUpdateUser(idInmo: number, idUser: number, bodyUser) {
    let body = JSON.stringify({ idInmo, idUser, bodyUser });
    let requestOptions = this.getRequestOptions();
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.INSERT_USER;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateProfileUser(idUser, bodyUser) {
    const body = JSON.stringify({ idUser, bodyUser });
    const requestOptions = this.getRequestOptions();
    const requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.UPDATE_PROFILE_USER;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateAgendaSettings(idUser, bodyUser, startDay) {
    const body = JSON.stringify({ idUser, bodyUser, startDay });
    const requestOptions = this.getRequestOptions();
    const requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.UPDATE_AGENDA_SETTINGS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateMatchingPassword(email, passwordSimple) {
    const body = JSON.stringify({ email, passwordSimple });
    const requestOptions = this.getRequestOptions();
    const requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.UPDATE_MATCHING_PASSWORD;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public migrateClients(previousUser, newUser) {
    let body = JSON.stringify({ previousUser: previousUser, newUser: newUser });
    let requestOptions = this.getRequestOptions();
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.MIGRATE_CLIENTS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  // public updateUserReportValues(userReportValues: Array<ReportObjectChanged>) {
  // 	let body = JSON.stringify({ 'userReportValues': userReportValues });
  // 	let requestOptions = this.getRequestOptions();
  // 	let requestURL = this.URL + Values.API_RESOURCES.USER + this.reqPattern + Values.USER_REQS.UPDATE_USER_REPORT_VALUES;
  // 	return this.http.post(requestURL, body).pipe(map(response => response));
  // }

  // public updateUserReportComission(comission: Array<ComissionChanged>) {
  // 	let body = JSON.stringify({ 'comission': comission });
  // 	let requestOptions = this.getRequestOptions();
  // 	let requestURL = this.URL + Values.API_RESOURCES.USER + this.reqPattern + Values.USER_REQS.UPDATE_USER_REPORT_COMISSION;
  // 	return this.http.post(requestURL, body).pipe(map(response => response));
  // }

  public getUserInfo(token: number) {
    let tokenParam = "&usT=";
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.GET_USER_INFO;
    requestURL += tokenParam + token;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public sendMailToRecoverPassword(email: string) {
    let body = JSON.stringify({ email: email });
    let requestURL = this.URL + Values.API_RESOURCES.LOGIN + this.reqPattern + Values.LOGIN_REQS.RECOVER_PASSWORD;
    // Joan: No es fa servir // let requestOptions = this.getRequestOptions();
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public checkCode(user: string, hashKey: string) {
    let body = JSON.stringify({ user: user, hashKey: hashKey });
    let requestURL = this.URL + Values.API_RESOURCES.LOGIN + this.reqPattern + Values.LOGIN_REQS.CHECK_CODE;
    // Joan: No es fa servir // let requestOptions = this.getRequestOptions();
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public setNewPassword(user: string, newPassword: string) {
    let body = JSON.stringify({ user: user, newPassword: newPassword });
    let requestURL = this.URL + Values.API_RESOURCES.LOGIN + this.reqPattern + Values.LOGIN_REQS.CHANGE_PASSWORD;
    // Joan: No es fa servir // let requestOptions = this.getRequestOptions();
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  /**
   *
   * Activites Part
   *
   */

  public getActivitiesPrefefinedValues(appModule, id) {
    const idParam = "&module=";
    const reqParam = appModule;
    const idValue = "&id=";
    const reqParam2 = id;
    let requestURL: any;
    requestURL =
      this.URL +
      Values.API_RESOURCES.ACTIVITIES +
      this.reqPattern +
      Values.ACTIVITIES_REQS.GET_ACTIVITIESPREDEFINEDVALUES;
    requestURL += idParam + reqParam;
    requestURL += idValue + reqParam2;

    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getCalendarOneActivity(id: number, idUser: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.ACTIVITIES +
      this.reqPattern +
      Values.ACTIVITIES_REQS.GET_ONEACTIVITIY;
    requestURL += "&id=" + id;
    requestURL += "&idUser=" + idUser;
    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public deletePeriodCalendarActivity(event) {
    let requestURL: any;
    requestURL =
      this.URL +
      Values.API_RESOURCES.ACTIVITIES +
      this.reqPattern +
      Values.ACTIVITIES_REQS.DELETE_CALENDAR_PERIOD_ACTIVITY;
    let body = JSON.stringify({ event });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  /************************
   *
   *
   * User Monitoring
   *
   *
   *************************/
  public insertMonitoringInformation(user_id, area, custom_id, staticHTML, paramControlVariables) {
    //(user_id, name, text1, text2, dId) {
    let params = {
      custom_id: custom_id,
      params: paramControlVariables,
    };
    const date = new Date();
    let information = {
      user_id: user_id,
      functional_area: area,
      //name: name,
      //view_visited: text1,
      //details: text2,
      detailsId: params,
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      },
      staticHTML: staticHTML,
    };
    let requestURL: any;

    requestURL =
      this.URL +
      Values.API_RESOURCES.USERMONITORING +
      this.reqPattern +
      Values.USER_MONITORING_REQS.INSERT;

    let body = JSON.stringify({ information });

    let requestOptions = this.getRequestOptions();

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateMonitoringInformation(id: number, staticHTML) {
    const date = new Date();
    let information = {
      id: id,
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      },
      staticHTML: staticHTML,
    };
    let requestURL: any;

    requestURL =
      this.URL +
      Values.API_RESOURCES.USERMONITORING +
      this.reqPattern +
      Values.USER_MONITORING_REQS.UPDATE;

    let body = JSON.stringify({ information });
    let data = JSON.stringify(information.date);
    let requestOptions = this.getRequestOptions();

    /*$.ajax({
      type: 'PUT',
      async: false,
      url: requestURL,
      data: "id="+id+"&data="+data,
      success: function(data){
        console.log('UPDATE PERFORMED', data);
        alert(data);
      }
  });*/
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertMonitoringIdFunction(user_id, area, id_function) {
    const date = new Date();
    let information = {
      user_id: user_id,
      functional_area: area,
      //name: name,
      //view_visited: text1,
      //details: text2,
      id_function: id_function,
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds(),
      },
    };

    let requestURL: any;
    requestURL = this.URL + Values.API_RESOURCES.USERMONITORING + this.reqPattern + Values.USER_MONITORING_REQS.INSERT_FUNCTIONS;

    let body = JSON.stringify({ information });

    let requestOptions = this.getRequestOptions();

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  private getJSONHeaders() {
    //let headers = new Headers({'Content-Type': 'application/json'});
    return "";
  }

  private getNoCacheHeaders() {
    //let headers = new Headers({'Cache-Control': 'no-cache, no-store, must-revalidate'});
    return "";
  }

  private getNoCacheOptions() {
    let noCacheHeaders = this.getNoCacheHeaders();
    //let requestOptions = new RequestOptions({headers: noCacheHeaders});
    return "";
  }

  private getRequestOptions() {
    let JSONHeaders = this.getJSONHeaders();
    //let requestOptions = new RequestOptions({headers: JSONHeaders});
    return "";
  }

  loadPredefinedValuesService(idModulo: number, fieldName: string) {
    const idModuloParam = "&idModulo=";
    const fieldNameParam = "&fieldName=";
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EVENTS +
      this.reqPattern +
      Values.EVENTS_REQS.LOAD_PREDEFINED_VALUES;
    requestURL += idModuloParam + idModulo;
    requestURL += fieldNameParam + fieldName;
    let options = this.getNoCacheOptions();
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  loadExternValuesService(idModulo: number) {
    const idModuloParam = "&idModulo=";
    //const fieldNameParam = '&fieldId=';
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EVENTS +
      this.reqPattern +
      Values.EVENTS_REQS.LOAD_EXTERN_VALUES;
    requestURL += idModuloParam + idModulo;
    //requestURL += fieldNameParam + fieldId;
    let options = this.getNoCacheOptions();
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public ContactForm(name, mail, inmoName, tel, comment) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EMAILTEMPLATES +
      this.reqPattern +
      Values.EMAILTEMPLATES_REQS.SEND_CONTACT_MAIL;
    requestURL +=
      "&name=" +
      name +
      "&mail=" +
      mail +
      "&inmoName=" +
      inmoName +
      "&tel=" +
      tel +
      "&comment=" +
      comment;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public InsertContactInfo(
    name,
    mail,
    inmoName,
    tel,
    comment,
    privacy,
    newsletter
  ) {
    let body = JSON.stringify({
      name: name,
      mail: mail,
      inmoName: inmoName,
      tel: tel,
      comment: comment,
      privacy: privacy,
      newsletter: newsletter,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EMAILTEMPLATES +
      this.reqPattern +
      Values.EMAILTEMPLATES_REQS.INSERT_CONTACT_INFO;
    // requestURL += '&name=' + name +'&mail=' + mail +'&inmoName=' + inmoName + '&tel=' + tel +'&comment=' + comment + '&privacy=' + privacy + '&newsletter=' + newsletter;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getAllMyAccountsUsers(idEmpresa: number, idUser: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.GET_ALL_MY_ACCOUNTS_USERS;
    requestURL += "&idEmpresa=" + idEmpresa;
    requestURL += "&idUser=" + idUser;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public insertScheduleView(schedulesArray, idUser: number) {
    let body = JSON.stringify({
      schedulesArray: schedulesArray,
      idUser: idUser,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.INSERT_SCHEDULE_VIEW;
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public deleteImageProfile(imgName, idUser: number, idInmo: number) {
    let body = JSON.stringify({
      imgName: imgName,
      idUser: idUser,
      idInmo: idInmo,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.DELETE_IMAGE_PROFILE;
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public getAllGroupUsersById(idGroup, idUser) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.ACTIVITIES +
      this.reqPattern +
      Values.ACTIVITIES_REQS.GET_ALL_GROUP_USERS_BY_ID;
    requestURL += "&idGroup=" + idGroup;
    requestURL += "&idUser=" + idUser;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public uploadTempleteImage(formData, idInmo) {
    const headers = new HttpHeaders();

    let requestURL =
      this.URL +
      Values.API_RESOURCES.EMAILTEMPLATES +
      this.reqPattern +
      Values.EMAILTEMPLATES_REQS.UPLOAD_TEMPLETE_IMAGE;

    requestURL += "&idInmo=" + idInmo;

    //return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));

    return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public insertEgoImage(formData) {
    const headers = new HttpHeaders();

    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.INSERT_EGO_IMAGE;

    console.log("PRUEBAS IMAGE");
    console.log(requestURL);
    console.log(Values);
    console.log(Values.EGONOTIFICATIONS_REQS);

    //return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));

    return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public deleteTempleteImage(url) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EMAILTEMPLATES +
      this.reqPattern +
      Values.EMAILTEMPLATES_REQS.DELETE_TEMPLETE_IMAGE;

    requestURL += "&url=" + url;

    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public deleteImageEgo(url) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.DELETE_IMAGE_EGO;

    requestURL += "&url=" + url;

    return this.http.post(requestURL, null).pipe(map((response) => response));
  }

  public commercialAcceptedVisit(userId, notification) {
    const body = JSON.stringify({ userId, notification });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.COMMERCIAL_ACCEPTED_VISIT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public commercialRejectedVisit(userId, notification) {
    const body = JSON.stringify({ userId, notification });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.COMMERCIAL_REJECTED_VISIT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getCommercialNotifications(userId) {
    const body = JSON.stringify({ userId });
    //console.log('BODY --> ' + JSON.stringify(body));
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.GET_COMMERCIAL_NOTIFICATIONS;
    //console.log('requestURL', requestURL);
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getActivitiesNotifications(userId) {
    const body = JSON.stringify({ userId });
    //console.log('BODY --> ' + JSON.stringify(body));
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.GET_ACTIVITIES_NOTIFICATIONS;
    //console.log('requestURL getActivitiesNotifications', requestURL);
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteNotification(notificationId: number) {
    let body = JSON.stringify({ notificationId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.DELETE_NOTIFICATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPlantilla(id, idEmpresa = null) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.REPORT +
      this.reqPattern +
      Values.REPORT_REQS.GET_PLANTILLA;
    requestURL += "&id=" + id + "&idEmpresa=" + idEmpresa;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getPlanesMovin() {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.GET_PLANES_MOVIN;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getPlanesComprados(idInmo: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.GET_PLANES_COMPRADOS;
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getPlanesUsados(idInmo: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.GET_PLANES_USADOS;
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public setPlanesMovin(
    idInmo,
    idUser,
    inmoNumber,
    finalPrice,
    idPlan,
    bodyInmo,
    bodyBill,
    n,
    dto,
    paid
  ) {
    let body = JSON.stringify({
      idInmo,
      idUser,
      inmoNumber,
      finalPrice,
      idPlan,
      bodyInmo,
      bodyBill,
      n,
      dto,
      paid,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.SET_PLANES_MOVIN;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public unsubscribeMovin(idInmo: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.UNSUBSCRIBE;
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public totalMovinCoin(idInmo: number) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      Values.BILL_REQS.TOTAL;
    requestURL += "&idInmo=" + idInmo;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public chargePaymentStripe(token, amount, description, idInmo) {
    let body = JSON.stringify({ token, amount, description, idInmo });
    let requestURL =
      this.URL + Values.API_RESOURCES.BILL + this.reqPattern + "charge-payment";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public checkInmoStripe(idInmo) {
    let body = JSON.stringify({ idInmo });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.BILL +
      this.reqPattern +
      "check-inmo-stripe";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public newNotiEgo(user, company, idPlantilla) {
    let body = JSON.stringify({ user, company, idPlantilla });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.REPORT_REQS.NEW_NOTI_EGO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getEgoNotification(screen, user, company) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.GET_EGO_NOTIFICATION;
    requestURL += "&screen=" + screen;
    requestURL += "&user=" + user;
    requestURL += "&company=" + company;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getEgoHomeNotifications(id, company) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.GET_EGO_HOME_NOTIFICATION;
    requestURL += "&usid=" + id;
    requestURL += "&company=" + company;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getEgoNotificationSuperior(screen, user, company) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.GET_EGO_SUPERIOR_NOTIFICATION;
    requestURL += "&screen=" + screen;
    requestURL += "&user=" + user;
    requestURL += "&company=" + company;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getQuestionsAndAnswers(screen) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.GET_QUESTIONS;
    requestURL += "&screen=" + screen;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  // ********************************************* MARKETPLACE *********************************************************

  public getModulosInmo(inmoId: number) {
    let body = JSON.stringify({ inmoId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_MODULOS_INMO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getModulosOfertas(inmoId: number) {
    let body = JSON.stringify({ inmoId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_MODULOS_OFERTAS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPacks() {
    // let body = JSON.stringify({});
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_PACKS;
    //return this.http.post(requestURL, body).pipe(map(response => response));
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public getModulosPacks(idPack: number) {
    let body = JSON.stringify({ idPack });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_MODULOS_PACKS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getModulosDependientes(inmoId: number, moduloId: number) {
    let body = JSON.stringify({ inmoId, moduloId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_MODULOS_DEPENDIENTES;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public inmoTieneModuloDependiente(
    inmoId: number,
    moduloDependienteId: number
  ) {
    let body = JSON.stringify({ inmoId, moduloDependienteId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.TIENE_MODULO_DEPENDIENTE;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertModuloInmo(
    inmoId: number,
    moduloId: number,
    precio: number,
    userId: number
  ) {
    let body = JSON.stringify({ inmoId, moduloId, precio, userId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.INSERT_MODULO_INMO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public desactivarModuloInmo(inmoId: number, moduloId: number) {
    let body = JSON.stringify({ inmoId, moduloId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.DESACTIVAR_MODULO_INMO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  //####################################################################################################################
  // NEW MARKETPLACE
  // 'get-products-of-company'
  // 'get-available-products'
  // 'get-dependencies-of-product'

  public getProductsOfCompany(
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number
  ) {
    let body = JSON.stringify({ idCompany, idTypeCompany, idLanguage });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-products-of-company";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getAvailableProducts(
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-available-products";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getInfoProduct(
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number,
    idModule: number
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
      idModule,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-info-product";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getComingSoonProducts(
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-coming-soon-products";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getDependenciesOfProduct(
    idModulo: number,
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number
  ) {
    let body = JSON.stringify({
      idModulo,
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-dependencies-of-product";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getDependentsOfProduct(idProduct: number, idLanguage: number) {
    let body = JSON.stringify({ idProduct, idLanguage });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-dependents-of-product";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getWidgetsByList(
    modules: any,
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number
  ) {
    let body = JSON.stringify({
      modules,
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-widgets";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getMovinsInmo(idCompany: number, idTypeCompany: number) {
    let body = JSON.stringify({ idCompany, idTypeCompany });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-movins";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public restarMovins(
    movinsRestar: number,
    idCompany: number,
    idTypeCompany: number
  ) {
    let body = JSON.stringify({
      movins: movinsRestar,
      idCompany,
      idTypeCompany,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "restar-movins";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public addProducts(
    idCompany: number,
    idTypeCompany: number,
    userId: number,
    carrito: any,
    totalCarro: number
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      userId,
      carrito,
      totalCarro,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "add-products";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertPlanMovin(
    idCompany: number,
    idTypeCompany: number,
    userId: number,
    movins: number,
    precio: number,
    paid: boolean
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      userId,
      movins,
      precio,
      discount: 0,
      active: paid,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "add-plan-movin";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public cancelarPlanMovin(idCompany: number, idTypeCompany: number) {
    let body = JSON.stringify({ idCompany, idTypeCompany });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "cancelar-plan";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public productosRelacionados(
    product: number,
    idCompany: number,
    idTypeCompany: number,
    idLanguage: number,
    idAdminArea: number
  ) {
    let body = JSON.stringify({
      product,
      idCompany,
      idTypeCompany,
      idLanguage,
      idAdminArea,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-productos-relacionados";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getMovinsAdquiridos(idCompany: number, idTypeCompany: number) {
    let body = JSON.stringify({ idCompany, idTypeCompany });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-movins-adquiridos";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getMovinsInvertidos(
    idCompany: number,
    idTypeCompany: number,
    languageId: number
  ) {
    let body = JSON.stringify({ idCompany, idTypeCompany, languageId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-movins-invertidos";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertarSingleCarrito(
    idCompany: number,
    idTypeCompany: number,
    idUser: number,
    carro: any,
    idLanguage: number
  ) {
    let body = JSON.stringify({
      idCompany,
      idTypeCompany,
      idUser,
      carro,
      idLanguage,
    });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "insert-single-carrito";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateSingleCarrito(id: number) {
    let body = JSON.stringify({ id });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "update-single-carrito";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteElementsCarrito(elements: any, userId: number) {
    let body = JSON.stringify({ elements, userId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "delete-elements-carrito";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  /*public crearCarritoCompra() {
    let body = JSON.stringify({ });
    let requestURL = this.URL + Values.API_RESOURCES.MARKETPLACE + this.reqPattern + 'create-carrito-compra';
    return this.http.post(requestURL, body).pipe(map(response => response));
  }*/

  public getCarritoItems(
    idCompany: number,
    idTypeCompany: number,
    idUser: number,
    languageId: number
  ) {
    let body = JSON.stringify({ idCompany, idTypeCompany, idUser, languageId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-carrito-items";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateProductsEstate(products: any, estate: boolean) {
    let body = JSON.stringify({ products, estate });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "update-products-estate";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public companyHaveSubscription(
    idCompany: number,
    companyType: number,
    adminArea: number
  ) {
    let body = JSON.stringify({ idCompany, companyType, adminArea });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "company-have-subscription";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  //********************************************************************************************************************

  public clickEgoMonitoreo(idNot, idStep, idButton, idArea, user, iniDate) {
    const endDate = new Date();
    let information = {
      id_not: idNot,
      id_step: idStep,
      id_button: idButton,
      functional_area: idArea,
      id_user: user,
      iniDate: {
        year: iniDate.getFullYear(),
        month: iniDate.getMonth() + 1,
        day: iniDate.getDate(),
        hour: iniDate.getHours(),
        minute: iniDate.getMinutes(),
        second: iniDate.getSeconds(),
      },
      endDate: {
        year: endDate.getFullYear(),
        month: endDate.getMonth() + 1,
        day: endDate.getDate(),
        hour: endDate.getHours(),
        minute: endDate.getMinutes(),
        second: endDate.getSeconds(),
      },
    };

    let requestURL: any;

    requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.CLICK_EGO_MONITOREO;

    let body = JSON.stringify({ information });

    let requestOptions = this.getRequestOptions();

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertEgoAction(idNot, horaInicio, horaFin, accion, infoAccion) {
    let body = JSON.stringify({
      idNot,
      horaInicio,
      horaFin,
      accion,
      infoAccion,
    });
    //console.log(body);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.INSERT_EGO_ACTION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteEgoNotification(idNot) {
    let body = JSON.stringify({ idNot });
    //console.log(body);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.DELETE_EGO_NOTIFICATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getInfoInmo(inmoId: number) {
    let body = JSON.stringify({ inmoId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.LANDING +
      this.reqPattern +
      Values.LANDING_REQS.GET_INFO_INMO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getInmoNameByUserId(userId: number) {
    let body = JSON.stringify({ userId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      Values.INMO_REQS.GET_NAME_INMO_BY_USER_ID;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public rejectUser(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      Values.INMO_REQS.REJECT_CANCEL_USER;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public queueEmail(email, id, buttonActionId, empresaId) {
    let body = JSON.stringify({ email, id, buttonActionId, empresaId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EVENTS +
      this.reqPattern +
      Values.EVENTS_REQS.QUEUE_EMAIL;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  // public createDynamicEgoNotification(idModulo, id, not, peticionInfoId) {
  public createDynamicEgoNotification(id, not, buttonActionId, empresaId) {
    let body = JSON.stringify({ id, not, buttonActionId, empresaId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EVENTS +
      this.reqPattern +
      Values.EVENTS_REQS.CREATE_DYNAMIC_EGO_NOTIFICATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptContract(idInmo) {
    let body = JSON.stringify({ idInmo });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.REPORT +
      this.reqPattern +
      Values.REPORT_REQS.ACCEPT_CONTRACT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptPrivacity(idUser) {
    let body = JSON.stringify({ idUser });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.REPORT +
      this.reqPattern +
      Values.REPORT_REQS.ACCEPT_PRIVACITY;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateEgoNotificationFinalHour(idNot, esInteractuacion) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.UPDATE_EGO_NOTIFICATION_FINAL_HOUR;
    const body = JSON.stringify({ idNot, esInteractuacion });
    //console.log(body);
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getEgoNotificationById(idNot) { // DUBTE no es fa servir però és ego
    let requestURL =
      this.URL +
      Values.API_RESOURCES.EGO_NOTIFICATIONS +
      this.reqPattern +
      Values.EGONOTIFICATIONS_REQS.GET_EGO_NOTIFICATION_BY_ID;
    const body = JSON.stringify({ idNot });
    //console.log(body);
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  // ************************* COMMUNITY ********************************************

  public rejectCollaboration(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.REJECT_COLLABORATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptInmoGroupRequest(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.ACCEPT_INMO_GROUP_REQUEST;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public rejectInmoGroupRequest(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.REJECT_INMO_GROUP_REQUEST;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptInmoGroupInvitation(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.ACCEPT_INMO_GROUP_INVITATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public rejectInmoGroupInvitation(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.REJECT_INMO_GROUP_INVITATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptUserGroupRequest(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.ACCEPT_USER_GROUP_REQUEST;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public rejectUserGroupRequest(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.REJECT_USER_GROUP_REQUEST;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public acceptUserGroupInvitation(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.ACCEPT_USER_GROUP_INVITATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public rejectUserGroupInvitation(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.REJECT_USER_GROUP_INVITATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public inmoLeaveGroup(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.INMO_LEAVE_GROUP;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public userLeaveGroup(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.USER_LEAVE_GROUP;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getChatContacts(idInmo, userToken) {
    let body = JSON.stringify({ idInmo, userToken });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.GET_CHAT_CONTACTS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public uploadChatGroupPicture(formData, nameImage, idGrupo) {
    /*let requestURL = this.URL + Values.API_RESOURCES.COMMUNITY + this.reqPattern + Values.COMMUNITY_REQS.UPLOAD_CHAT_GROUP_PICTURE;
    const body = JSON.stringify({ nameImage, idGrupo });
    return this.http.post(requestURL, body).pipe(map(response => response));*/
    const headers = new HttpHeaders();
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.UPLOAD_CHAT_GROUP_PICTURE;
    requestURL += "&nameImage=" + nameImage;
    requestURL += "&idGrupo=" + idGrupo;
    //return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));
    return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  public updateChatGroupPicture(formData, nameImage, idGrupo) { // DUBTE no es fa servir però és community
    const headers = new HttpHeaders();

    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.UPLOAD_CHAT_GROUP_PICTURE;

    requestURL += "&nameImage=" + nameImage;
    requestURL += "&idGrupo=" + idGrupo;

    //return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));

    return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: "events",
    });

    //let requestURL = this.URL + Values.API_RESOURCES.INMO + this.reqPattern + Values.INMO_REQS.POST_AVATAR_IMAGE_URL;
    /*let requestURL = this.URL + Values.API_RESOURCES.COMMUNITY + this.reqPattern + Values.COMMUNITY_REQS.UPDATE_CHAT_GROUP_PICTURE;
    requestURL += '&nameImage=' + nameImage;
    requestURL += '&idGrupo=' + idGrupo;

    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    //let options = new RequestOptions({ headers: headers });
    return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));*/

    // return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));

    //let requestURL = this.URL + Values.API_RESOURCES.COMMUNITY + this.reqPattern + Values.COMMUNITY_REQS.UPLOAD_CHAT_GROUP_PICTURE;

    //const body = JSON.stringify({ nameImage, idGrupo });

    // return this.http.post(requestURL, formData, { headers }).pipe(map(response => response));
    // return this.http.post(requestURL, body).pipe(map(response => response));

    /*return this.http.post<any>(requestURL, formData, {
      reportProgress: true,
      observe: 'events'
    });*/
    //const body = JSON.stringify({ nameImage, idGrupo });
    //return this.http.post(requestURL, body).pipe(map(response => response));
  }

  public acceptCollaboration(user, noti) {
    let body = JSON.stringify({ user, noti });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.ACCEPT_COLLABORATION;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getUserChatInfo(userToken) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.GET_USER_CHAT_INFO;
    const body = JSON.stringify({ userToken });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getRoomInfo(userToken) { // DUBTE no es fa servir però és community
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.GET_ROOM_INFO;
    const body = JSON.stringify({ userToken });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getCommunityAdminsForChatInmoGroup(
    inmoSolicitadaId,
    tokenUsuarioSolicitante
  ) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMUNITY +
      this.reqPattern +
      Values.COMMUNITY_REQS.GET_COMMUNITY_ADMINS_FOR_CHAT_INMO_GROUP;
    const body = JSON.stringify({ inmoSolicitadaId, tokenUsuarioSolicitante });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  // ********************************************************************************

  public cancelInmoAccount(userId, inmoId, inactive) {
    let body = JSON.stringify({ userId, inmoId, inactive });

    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      Values.INMO_REQS.CANCEL_INMO_ACCOUNT;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public cancelGroupAccount(userId, groupId, inactive) {
    let body = JSON.stringify({ userId, groupId, inactive });

    let requestURL =
      this.URL +
      Values.API_RESOURCES.GROUP +
      this.reqPattern +
      Values.LOGIN_REQS.CANCEL_GROUP_ACCOUNT;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public adminCountInmo(inmoId) {
    let body = JSON.stringify({ inmoId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      Values.INMO_REQS.COUNT_ADMIN_INMO;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  // Locations GeoName
  public getLocations(params: Array<string>, full: boolean) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.LOCATIONS +
      this.reqPattern +
      Values.LOCATION_REQS.GET_LOCATIONS;
    const body = JSON.stringify({ params, full });
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPlantillaEgoButtonApp(idProduct, userId) {
    let body = JSON.stringify({ idProduct, userId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_PLANTILLA_EGO_BUTTON_APP;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertPlantillaEgoButtonAppGenericProduct(
    idProduct,
    userId,
    empresaId,
    $from
  ) {
    /* FROM --> 1: venta, 2: tutorial */
    let body = JSON.stringify({ idProduct, userId, empresaId, $from });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.INSERT_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getIdPlantillaEgoButtonAppGenericProduct(idProduct, $from) {
    /* FROM --> 1: venta, 2: tutorial */
    let body = JSON.stringify({ idProduct, $from });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_ID_PLANTILLA_EGO_BUTTON_APP_GENERIC_PRODUCT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public setDemoRealizada(userId, idProduct) {
    let body = JSON.stringify({ userId, idProduct });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.INSERT_CONFIG_PRODUCT;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getDemosInfo(userId: number, productos: any) {
    let body = JSON.stringify({ userId, productos });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      Values.MARKETPLACE_REQS.GET_DEMOS_INFO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getInternalRouteById(id) {
    let body = JSON.stringify({ id });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_ROUTE_BY_ID;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getStructure(data, paramControlVariables, paramsExternalWindow = false) {
    let params = null;
    let paramControl = paramControlVariables;
    if (paramControl != null) params = paramControl;
    if (params != null && data['idCompanyGeneric'] && params[data['idCompanyGeneric']]) params = paramControl;
    //let paramsExternalWindowB = {paramsExternalWindow: paramsExternalWindow};
    let body = JSON.stringify({ data, params, paramsExternalWindow });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.RETRIEVE_STRUCTURE;
      
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getSelectionOptions(data, idQuery) {
    let body = JSON.stringify({ data, idQuery });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_DATA_BY_ID;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateResults(elements, tables, data, params) {
    console.log(elements, tables, data, params);
    let body = JSON.stringify({ elements, tables, data, params });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.UPDATE_RESULTS;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public insertFormData(data, formFields: any, removed: any, imageUpload = [], params = null, userChanges = {}): any {
    let formData: FormData = new FormData();
    formData.append("fields", JSON.stringify({ data, formFields, removed, params, userChanges }));
    if (imageUpload.length > 0) {
      let files = [];
      let j = 0;
      for (let i in imageUpload) {
        if (imageUpload[i]["tmp_value"]) {
          imageUpload[i]["tmp_value"].forEach((t) => {
            formData.append(String(j), t);
            j++;
          });
        }
      }
    }
    //formData.forEach((r) => console.log("FORMDATA: ", r));
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    //let body = JSON.stringify({data, formFields, removed});
    //console.log(body);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.INSERT_FORM_DATA;

    return this.http.post(requestURL, formData, { headers }).pipe(map((response) => response));
  }

  public insertFormDataInfo(data, formFields: any, removed: any, imageUpload = [], params = null, userChanges = {}): any {
    let formData: FormData = new FormData();
    formData.append("fields", JSON.stringify({ data, formFields, removed, params, userChanges }));
    if (imageUpload.length > 0) {
      let files = [];
      let j = 0;
      for (let i in imageUpload) {
        if (imageUpload[i]["tmp_value"]) {
          imageUpload[i]["tmp_value"].forEach((t) => {
            formData.append(String(j), t);
            j++;
          });
        }
      }
    }
    //formData.forEach((r) => console.log("FORMDATA: ", r));
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    //let body = JSON.stringify({data, formFields, removed});
    //console.log(body);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.INSERT_FORM_DATA_INFO;

    return this.http.post(requestURL, formData, { headers }).pipe(map((response) => response));
  }

  public deleteData(formFields): any {
    let body = JSON.stringify({ formFields });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.DELETE_DATA;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public loadTableDisplays(data): any {
    let body = JSON.stringify({ data });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.LOAD_TABLE_DISPLAYS;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public parseLanguageTag(params: any): any {
    let body = JSON.stringify({ params });
    console.log(body);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.PARSE_LANGUAGE_TAG;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getAdminAreaByInmo(inmoId: number) {
    let body = JSON.stringify({ inmoId });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.COMMON +
      this.reqPattern +
      Values.COMMON_REQS.GET_ADMIN_AREA_BY_INMO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getVoucherCodeDiscounts(
    voucherCode: string,
    idAdminArea: number,
    idCompanyType: number
  ) {
    let body = JSON.stringify({ voucherCode, idAdminArea, idCompanyType });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MARKETPLACE +
      this.reqPattern +
      "get-voucher-code-discounts";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getCalendarsGoogle(user) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.GET_CALENDARS;
    requestURL += "&user=" + user;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public updateDefaultEmpresaCalendarioUsuario(
    idEmpresa,
    idUsuario,
    idCalendarioFrom
  ) {
    let body = JSON.stringify({ idEmpresa, idUsuario, idCalendarioFrom });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.UPDATE_DEFAULT_EMPRESA_CALENDARIO_USUARIO;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public vincularGoogleCalendar(user, idEmpresa) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.VINCULAR_GOOGLE_CALENDAR;
    let body = JSON.stringify({ user, idEmpresa });
    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public setCalendarsGoogle(user, calendar) {
    let body = JSON.stringify({ user, calendar });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.SET_CALENDARS_GOOGLE;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public unsetCalendarsGoogle(user) {
    let body = JSON.stringify({ user });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.UNSET_ALL_CALENDARS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public desvincularGoogleCalendar(user) {
    let body = JSON.stringify({ user });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.DESVINCULAR_CALENDAR_GOOGLE;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public setCalendarsMicrosoft(user, calendar) {
    let body = JSON.stringify({ user, calendar });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MICROSOFT +
      this.reqPattern +
      Values.MICROSOFT_REQS.SET_MICROSOFT_CALENDARS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public vincularMicrosoftCalendar(user, idEmpresa) {
    let body = JSON.stringify({ user, idEmpresa });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MICROSOFT +
      this.reqPattern +
      Values.MICROSOFT_REQS.VINCULAR_MICROSOFT_CALENDAR;

    return this.http
      .post<any[]>(requestURL, body)
      .pipe(map((response) => response));
  }

  public desvincularMicrosoftCalendar(id) {
    let body = JSON.stringify({ id });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MICROSOFT +
      this.reqPattern +
      Values.MICROSOFT_REQS.DESVINCULAR_MICROSOFT_CALENDAR;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public unsetCalendarsMicrosoft(user) {
    let body = JSON.stringify({ user });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MICROSOFT +
      this.reqPattern +
      Values.MICROSOFT_REQS.UNSET_ALL_CALENDARS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getCalendarsMicrosoft(user) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.MICROSOFT +
      this.reqPattern +
      Values.MICROSOFT_REQS.GET_CALENDARS;
    requestURL += "&user=" + user;
    return this.http.get<any[]>(requestURL).pipe(map((response) => response));
  }

  public duplicateFunctionalAreaDB(data) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      "duplicate-functional-area-db";
    data = JSON.stringify({ data });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getTextInmueble(value, idLanguage, type) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_TEXT_INMUEBLE;
    let data = JSON.stringify({ value, idLanguage, type });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public saveTextInmueble(value, idLanguage, type, valueInsert) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.SAVE_TEXT_INMUEBLE;
    let data = JSON.stringify({ value, idLanguage, type, valueInsert });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getConditionsFromEvento(id, type) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_CONDITIONS_FROM_EVENTO;
    let data = JSON.stringify({ id, type });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getFieldsNameTable(tableName) {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_FIELDS_NAME_TABLE;
    let data = JSON.stringify({ tableName });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getEventosTables() {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_EVENTOS_TABLES;
    let data = JSON.stringify({});
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getOperatorsConditions() {
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_OPERATORS_CONDITIONS;
    let data = JSON.stringify({});
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

	/**
   * Get Elements Generic creator
   * @returns
   */
  public getElementsGenericCreator(dataAPI) {

    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_ELEMENTS_GENERIC_CREATOR;
    let data = JSON.stringify({data: dataAPI});
    return this.http.post(requestURL, data).pipe(map(response => response));
  }

  public updateGenericCreatorStructure(json) {
    console.log('type', typeof json)
    console.log('type', json)
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.UPDATE_FA_CUSTOM;
      console.log('requestURL', requestURL, this.detectCircularElements(json));
      //let data = this.stringify(json)
      let data = JSON.stringify(json);
      console.log('data type', data);

    return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public checkCircular(key, value, seen, circularElements, path, lastKeys, fa) {
    if(key == "id_functional_area") fa = value;
    lastKeys.push(key);
    if (typeof value === "object" && value !== null) {
      const objPath = [...path, key];

      if (seen.has(value)) {
        // Elemento circular encontrado
        circularElements.push({
          functional_area: fa,
          path: objPath,
          value,
          lastKeys
        });
        return false;
      }
      seen.add(value);
      // Recorrer propiedades del objeto
      for (const prop in value) {
         let v = this.checkCircular(prop, value[prop], seen, circularElements, path, lastKeys, fa);
         if(!v) return v;
      }
    }
    return true;
  }

  public detectCircularElements(obj, path = []) {
    const seen = new Set();
    const circularElements = [];
  
  
    // Recorrer propiedades del objeto principal
    for (const prop in obj) {
      let v = this.checkCircular(prop, obj[prop], seen, circularElements, path, [], 0);
      if(!v) break;
    }
  
    return circularElements;
  }

  public loadDeferTableData(element, elements) {
    let data = JSON.stringify({ element, elements });

    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.LOAD_DEFER_TABLE_DATA; 
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public payGeneric(idPayment, idPaymentMethod) {
    let data = JSON.stringify({idPayment, idPaymentMethod});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.PAYMENT +
      this.reqPattern +
      Values.PAYMENT_REQS.PAY;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public updatePaymentMethod(idPayment, idPaymentMethod, paid) {
    let data = JSON.stringify({idPayment, idPaymentMethod, paid});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.PAYMENT +
      this.reqPattern +
      Values.PAYMENT_REQS.UPDATE_PAYMENT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getDBSchema(id_schema, showOptions) {
    let body = JSON.stringify({ id_schema, showOptions });
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_DB_SCHEMA;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getDBSchemaWindow(info, showOptions) {
    let body = JSON.stringify({ info, showOptions });
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_DB_SCHEMA_WINDOW;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getOrganizationChart(idCompany) {
    let body = JSON.stringify({ idCompany });
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_ORGANIZATION_CHART;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public deleteAllDocs(idDb, idTable, idField, value) {
    let data = JSON.stringify({idDb, idTable, idField, value});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.DELETE_ALL_DOCS;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public saveControllerModelCode(idControllerModel, type, code, name) {
    let data = JSON.stringify({idControllerModel, type, code, name});
    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.SAVE_CONTROLLER_MODEL_CODE;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getControllerModelCode(idControllerModel) {
    let data = JSON.stringify({idControllerModel});
    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_CONTROLLER_MODEL_CODE;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public saveClientScriptCode(idClientScript, name, code) {
    let data = JSON.stringify({idClientScript, name, code});
    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.SAVE_CLIENT_SCRIPT_CODE;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getClientScriptCode(idClientScript) {
    let data = JSON.stringify({idClientScript});
    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.GET_CLIENT_SCRIPT_CODE;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printTicketOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printInvoice";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printCommandOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printCommand";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printMovementOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printMovement";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printCierreOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printCierreCaja";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printSnapshotOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printCierreCajaSnapshot";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public printPedidoOut(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/printPedido";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }
    
  public openCashRest(data, ip) {
    // Aquí va la IP del WIFI donde se está ejecutando el programa en local
    let requestURL = "http://" + ip + ":3000/cashdraw";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public saveUserParams(newLocal, idUser, idCompany, params) {
    let data = JSON.stringify({newLocal: newLocal, params: params, idUser: idUser, idCompany: idCompany});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.SAVE_USER_PARAMS;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }
  
  public updateParamControlFA(idFa, idFa_initial, index, idUser, idCompany, params, staticHTML) {
    let data = JSON.stringify({idFa: idFa, idFa_initial: idFa_initial, index: index, params: params, idUser: idUser, idCompany: idCompany, staticHTML: staticHTML});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.SAVE_USER_PARAMS_FA;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getTablesFromDatabase(idDb) {
    idDb = +idDb;
    let data = JSON.stringify({idDb});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_TABLES_FROM_DATABASE;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getFieldsFromTable(idDb, table) {
    idDb = +idDb;
    let data = JSON.stringify({idDb, table});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_FIELDS_FROM_TABLE;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public searchGeneric(value, idCompany, labelLanguage) {
    let data = JSON.stringify({value, idCompany, labelLanguage});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.SEARCH_GENERIC;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getMenuGeneric(idCompany, idUser, idProfile, idLanguage, idClientIntranet) {
    let data = JSON.stringify({idCompany, idUser, idProfile, idLanguage, idClientIntranet});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.GET_MENU_GENERIC;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public saveDeletedFaByUser(idUser, idFas, idFaInitial) {
    let data = JSON.stringify({idUser, idFas, idFaInitial});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.SAVE_DELETED_FA_BY_USER;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public saveDeletedFaByCompany(idCompany, idFas, idFaInitial) {
    let data = JSON.stringify({idCompany, idFas, idFaInitial});
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.SAVE_DELETED_FA_BY_COMPANY;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getFileFromUrl(url, path) {
    let headers = new HttpHeaders();
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      "download-file";
    let body = JSON.stringify({ url, path });
    return this.http
      .post(requestURL, body, { responseType: "blob" })
      .pipe(map((response) => response));
  }

  public runClientScriptCron(idClientScript, name) {
    let data = JSON.stringify({idClientScript, name});
    let requestURL = 
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.RUN_CLIENT_SCRIPT_CRON;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public vincularGoogleGmail(bodyInmoEmail) {
    let body = JSON.stringify({ bodyInmoEmail });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GOOGLE +
      this.reqPattern +
      Values.GOOGLE_REQS.VINCULAR_GOOGLE_GMAIL;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getPinWaiter(pin, userId) {
    let data = JSON.stringify({pin, userId});
    console.log("data ts:", data);
    let requestURL =
      this.URL +
      Values.API_RESOURCES.USER +
      this.reqPattern +
      Values.USER_REQS.GET_PIN_WAITER;
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public reloadTable(allData) {
    let body = JSON.stringify({ allData });
    let requestURL = this.URL + Values.API_RESOURCES.GENERIC + this.reqPattern + Values.GENERIC_REQUEST.RELOAD_TABLE;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public makePay(id_empresa, amount, id_db, bd_table, bd_field, value, value_pk, id_currency) {
    let data = JSON.stringify({id_empresa, amount, id_db, bd_table, bd_field, value, value_pk, id_currency});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.PAYMENT +
      this.reqPattern +
      Values.PAYMENT_REQS.MAKE_PAYMENT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public updateConditionsCustom(conditions) {
    let body = JSON.stringify({ conditions });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.UPDATE_CONDITIONS;
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public loadClientsFromFile(
    data,
    formFields: any,
    removed: any,
    imageUpload,
    params
  ) {
    let formData: FormData = new FormData();
    formData.append(
      "fields",
      JSON.stringify({ data, formFields, removed, params })
    );
    if (imageUpload.length > 0) {
      let files = [];
      let j = 0;
      for (let i in imageUpload) {
        if (imageUpload[i]["tmp_value"]) {
          imageUpload[i]["tmp_value"].forEach((t) => {
            formData.append(String(j), t);
            j++;
          });
        }
      }
    }
    formData.forEach((r) => console.log("FORMDATA: ", r));
    let headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");
    let requestURL =
      this.URL +
      Values.API_RESOURCES.LOAD_DATA_FROM_FILE +
      this.reqPattern +
      Values.LOAD_DATA_FROM_FILE.LOAD_CLIENTS_FROM_FILE; 
    console.log(requestURL);

    return this.http
      .post(requestURL, formData, { headers })
      .pipe(map((response) => response));
  }

  public updateJourneysCreator(elementsJourney) {
    let body = JSON.stringify({ elementsJourney });
    let requestURL = this.URL + "growthtool" + this.reqPattern + "update-journeys-creator";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updatePlantillaElementsCustom(elementsPlantilla, subjects, infoPlantilla) {
    let body = JSON.stringify({ elementsPlantilla, subjects, infoPlantilla });
    let requestURL =
      this.URL +
      "growthtool" +
      this.reqPattern +
      "update-elements-plantilla";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getChipsInfo(idCompany) {
    let data = JSON.stringify({ idCompany });
    let requestURL =
      this.URL +
      "growthtool" +
      this.reqPattern +
      "get-chips-plantillas-custom";
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getElementsPlantilla(idPlantilla, idEmpresa) {
    let requestURL =
      this.URL +
      "growthtool" +
      this.reqPattern +
      "get-elements-plantilla";
    let data = JSON.stringify({ idPlantilla, idEmpresa });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public duplicatePlantillaEmail(id, idCreator, idEmpresa, idNewPlantilla, nombre, descripcion) {
    let requestURL =
      this.URL +
      "growthtool" +
      this.reqPattern +
      "duplicate-plantillas-email";
    let data = JSON.stringify({ id, idCreator,idEmpresa, idNewPlantilla, nombre, descripcion });
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getJourneysCreator(idJourney, idCompany) {
    let data = JSON.stringify({ idJourney, idCompany });

    let requestURL = 
      this.URL +
      "growthtool" +
      this.reqPattern +
      "get-journeys-creator"; 
    return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public insertEmailReuseElement(structure, elementsPlantilla, subjects) {
    let body = JSON.stringify({ structure, elementsPlantilla, subjects });
    let requestURL =
      this.URL +
      "growthtool" +
      this.reqPattern +
      "insert-email-elements-reuse";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }
}

export interface ResponseInterface {
  errorCode: number;
  errorMessage: string;
  response: any;
}
