import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Values } from "../../../values/values";

@Injectable()
export class EndpointRustService {
  private URL = Values.ROOT_URL_RUST + '/';
  
  constructor(private http: HttpClient) {}

  public loadTableDisplays(body: any): any {
    const requestURL = this.URL + "table-displays";

    return this.http.post(
      requestURL,
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(map((response) => response));
  }

  public searchGeneric(value, idCompany, labelLanguage): any {
    const requestURL = this.URL + "search-generic";
    let body = {value, idCompany, labelLanguage};
    return this.http.post(
      requestURL,
      body,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
    ).pipe(map((response) => response));
  }
}