<div fxLayout="row wrap" fxFlex="100">
    <div *ngIf="showHeights" fxLayout="row wrap" class="heightsAgenda" fxFlex="100">
        <mat-form-field appearance="outline">
          <mat-label>Tamaño de las horas</mat-label>
          <input
            #filterInput
            placeholder="Introduce el tamaño de las horas"
            matInput
            type="number"
            [(ngModel)]="hourSegmentHeight"
            (ngModelChange)="onHourSegmentHeightChange($event)"
            min="70"
          />
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label>Divisiones de las horas</mat-label>
          <input
            #filterInput
            placeholder="Introduce las divisiones de las horas"
            matInput
            type="number"
            [(ngModel)]="hourSegments"
            (ngModelChange)="onHourSegmentsChange($event)"
            min="1"
          />
        </mat-form-field>
        <span class="badge-form-change second-bar-tools icons-header-custom close-heights-agenda">
          <button class="btn btnjan eseee" (click)="showHeightsFunction()" matTooltip="Finalizar editar tamaños de la agenda"><i class="material-icons">close</i></button>
        </span>
      </div>
    <div class="row top-home" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center" class="container">
        <div class="col-md-4 theselector">
            <div class="btn-group" style="float: left">
            <div class="btn btn-primary" (click)="this.view = CalendarView.Month" [class.active]="view === CalendarView.Month">
                <i class="material-icons">calendar_view_month</i> Mes
            </div>
            
            <div class="btn btn-primary" (click)="this.view = CalendarView.Week" [class.active]="view === CalendarView.Week">
                <i class="material-icons">calendar_view_week</i> Semana
            </div>
            
            <div class="btn btn-primary" (click)="this.view = CalendarView.Day" [class.active]="view === CalendarView.Day">
                <i class="material-icons">calendar_view_day</i> Día
            </div>
            </div>
        </div>
    
        <div class="col-md-4 text-center align-text-middle thedate">
            <div class="arrows text-right" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_back</i></div>
            <mat-form-field appearance="none" class="matFormFieldForDatePicker">
                <input style="display: none;" matInput [matDatepicker]="datepicker" [(ngModel)]="viewDate">
                <button class="datepicker" (click)="datepicker.open()">{{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}<!--<mat-icon>date_range</mat-icon>--></button>
                <mat-datepicker #datepicker startView="year" [startAt]="viewDate" ></mat-datepicker>
            </mat-form-field>
            <div class="arrows text-left" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()"><i class="material-icons">arrow_forward</i></div>
        </div>

        <div class="col-md-4 thebuttons" fxLayout="row" fxLayoutAlign="end center">
            <span *ngIf="!showHeights" class="badge-form-change second-bar-tools icons-header-custom">
                <button class="btn btnjan eseee" (click)="showHeightsFunction()" matTooltip="Editar tamaños de la agenda"><i class="material-icons">aspect_ratio</i></button>
              </span>
              <span *ngIf="showHeights" class="badge-form-change second-bar-tools icons-header-custom">
                <button class="btn btnjan eseee" (click)="showHeightsFunction()" matTooltip="Finalizar editar tamaños de la agenda"><i class="material-icons">close</i></button>
              </span>
        </div>
    </div>
    
    <div [ngSwitch]="view" *ngIf="loaded" fxFlex="100">
        <mwl-calendar-month-view
          *ngSwitchCase="CalendarView.Month"
          [viewDate]="viewDate"
          [events]="customEvents"
          [refresh]="refresh"
          [activeDayIsOpen]="activeDayIsOpen"
          (dayClicked)="dayClicked($event.day)"
          (eventClicked)="handleEvent('Clicked', $event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [cellTemplate]="customCellTemplate"
          (beforeViewRender)="beforeMonthViewRender($event)"
        >
        </mwl-calendar-month-view>
        <mwl-calendar-week-view
          *ngSwitchCase="CalendarView.Week"
          [viewDate]="viewDate"
          [events]="customEvents"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event)"
          (hourSegmentClicked)="handleEvent('HourClicked', $event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          [weekendDays]="weekendDays"
          [dayStartHour]="dayStartHour"
          [dayEndHour]="dayEndHour"
          [hourSegmentHeight]="this.hourSegmentHeight"
          [hourSegments]="this.hourSegments"
          (beforeViewRender)="beforeMonthViewRenderWeek($event)"
        >
        </mwl-calendar-week-view>
        <mwl-calendar-day-view
          *ngSwitchCase="CalendarView.Day"
          [viewDate]="viewDate"
          [events]="customEvents"
          [refresh]="refresh"
          (eventClicked)="handleEvent('Clicked', $event)"
          (eventTimesChanged)="eventTimesChanged($event)"
          [locale]="locale"
          (hourSegmentClicked)="handleEvent('HourClicked', $event)"
          [dayStartHour]="dayStartHour"
          [dayEndHour]="dayEndHour"
          [hourSegmentHeight]="this.hourSegmentHeight"
          [hourSegments]="this.hourSegments"
          (beforeViewRender)="beforeMonthViewRenderDay($event)"
        >
        </mwl-calendar-day-view>
      </div>
</div>
