<div>
    <!-- COUNTER: tiempo transcurrido -->
    <ng-container *ngIf="type === 'counter'">
      <h1 style="margin: 0;">
        <!-- Años -->
        <span *ngIf="years !== 0">
          {{ years }} {{ pluralize(years, 'año', 'años') }}
        </span>
  
        <!-- Meses -->
        <span *ngIf="months !== 0">
          {{ months }} {{ pluralize(months, 'mes', 'meses') }}
        </span>
  
        <!-- Días -->
        <span *ngIf="days !== 0">
          {{ days }} {{ pluralize(days, 'día', 'días') }}
        </span>
  
        <!-- Horas y minutos con dos dígitos; segundos opcionales -->
        {{ twoDigits(hours) }}:{{ twoDigits(minutes) }}h
        <!-- Para incluir segundos en el contador, añade: :{{ twoDigits(seconds) }} -->
      </h1>
    </ng-container>
  
    <!-- COUNTDOWN: tiempo restante -->
    <ng-container *ngIf="type === 'countdown'">
      <h1 style="margin: 0;">
        <!-- Años -->
        <span *ngIf="years !== 0">
          {{ years }} {{ pluralize(years, 'año', 'años') }}
        </span>
  
        <!-- Meses -->
        <span *ngIf="months !== 0">
          {{ months }} {{ pluralize(months, 'mes', 'meses') }}
        </span>
  
        <!-- Días -->
        <span *ngIf="days !== 0">
          {{ days }} {{ pluralize(days, 'día', 'días') }}
        </span>
  
        <!-- Horas:Minutos:Segundos, siempre dos dígitos -->
        {{ twoDigits(hours) }}:{{ twoDigits(minutes) }}:{{ twoDigits(seconds) }}h
      </h1>
    </ng-container>
  </div>
  