import { Injectable } from "@angular/core";
import { FunctionsClientService } from 'app/services-client/functions-client.service';
import { GenericService } from "./generic.service";
import { FunctionsService } from "./functions.service";


@Injectable({
  providedIn: "root",
})
export class FunctionsControllerService {
  constructor(
    private genericService: GenericService,
    public functionsClientService: FunctionsClientService,
    public functionsService: FunctionsService
  ) { }

  
  public getFunction(structure, param = null, finished = null, idFunction = null, autoExecuteFunction = false, isFromField = false) {
    if (structure && structure !== undefined) structure['autoExecuteFunction'] = autoExecuteFunction;

    // if need intern_param use this function in your function case --> this.genericService.getInternParam(structure, param);
    // if need input_param use this function in your function case --> this.getInputParam(structure);

    if (!isFromField && event) event.stopPropagation();

    if(structure && structure["creatingMode"] !== undefined && structure["creatingMode"]) {
      this.genericService.finishFunction(finished);
      return;
    }

    if (structure !== undefined && !structure["autoExecuteFunction"] && (structure["readMode"] !== undefined && structure["readMode"])) {
      this.genericService.openSnackBar("Parece que solo tienes permiso de lectura.", 7000, ["red-snackbar",]);
      this.genericService.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = true;

    if (!this.genericService.actualFunctionFinish) {
      if (structure !== undefined || !structure["autoExecuteFunction"]) {
        this.genericService.openSnackBar("Parece que ya hay otra función en marcha.", 7000, ["red-snackbar",]);
      }
      this.genericService.finishFunction(finished);
      return;
    }

    this.genericService.actualFunctionFinish = false;
    let id = this.genericService.cloneVariable(idFunction);
    if (structure !== undefined && id == null) {
      id = this.genericService.cloneVariable(structure["id_function"]);
    }
    if(!id) return;
    console.log(id, "id_function_clicked", structure);
    if (id && structure !== undefined && this.functionsService.checkPermissions(structure, finished)) {    
      if (this.functionsService.bottomSheetRefMenu !== null) this.functionsService.bottomSheetRefMenu.dismiss();
      if (id == 20 && structure["internal_routing"] && structure["internal_routing"] != null) id = 1;
      let ids = id.toString().split(",");
      for (let i in ids) {
        let newIdFunction = parseInt(ids[i]);
        if (!(newIdFunction > 0)) continue;
        this.functionsClientService.executeFunction(newIdFunction, structure, param, finished, isFromField);
        if (structure !== undefined && id > 0 && !isFromField) {
          /*this.endpointClientService.insertMonitoringIdFunction(this.authService.userId, structure["id_functional_area"], id).subscribe((data) => {
            console.log("monitoring_functions", data);
          });*/
        }
      }
    }
  }

  public getFunctionFormField(structure, autoExecuteFunction = false) {
    if (structure['form'] && structure['form']["controls"] && structure["bd_field"] && structure['form']["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]]) {
      structure['autoExecuteFunction'] = structure['form']["controls"][structure["id_functional_area"] + "-" + structure["bd_field"]].touched;
    }
    if(structure['addedAsignValuePreloaded']) {
      structure['autoExecuteFunction'] = false;
      structure['addedAsignValuePreloaded'] = false;
      autoExecuteFunction = false;

    }
    this.getFunction(structure, null, null, null, autoExecuteFunction, true);
  }
  
  public executeFunctionFromForm(parent, structure, newValue, old, type) {
    if (structure["id_function"]) {
      if (!structure["field_changed"]) {
        let formsChanged = this.genericService.getFormsCurrentPage(parent, true);
        let formsChanged_pre = this.genericService.cloneVariable(formsChanged);
        for (let key in formsChanged_pre) {
          if (formsChanged_pre[key]["id_functional_area"] != structure["id_functional_area"]) continue;
          if ((formsChanged_pre[key]["lastValueOnExecuteFunction"] === undefined && newValue !== old) || formsChanged_pre[key]["lastValueOnExecuteFunction"] !== newValue) {
            this.genericService.formsChanged[parent][this.genericService.paramControlVariables[parent]['indexParam']][formsChanged_pre[key]["type"] + formsChanged_pre[key]["id_functional_area"]]["lastValueOnExecuteFunction"] = newValue;
            // EJECUTAMOS FUNCIÓN
            this.getFunctionFormField(structure);
            structure["field_changed"] = true;
          }
        }
      } else {
        if(structure['tmp_value_old'] === undefined || newValue != structure['tmp_value_old']) {
          this.getFunctionFormField(structure);
        }
      }
    }
  }

  public updateLastValue(structure, internal_new) {
    if(structure['tmp_value_old_pre'] === undefined) structure['tmp_value_old'] = null;
    else structure['tmp_value_old'] = this.genericService.cloneVariable(structure['tmp_value_old_pre']);
    structure['tmp_value_old_pre'] = this.genericService.cloneVariable(internal_new);
  }

  public updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField = null, hasParameters = true) {
    this.updateLastValue(structure, internal_new);
    
    // ACTUALIZAMOS EL HISTORIAL DE CAMBIOS
    if (structure && structure['id_functional_status_general'] != 2 && structure['hide'] == 0) {
      this.genericService.updateFormHistory(structure, id_dsb, parent, id_parent, id_functional_area, type, old, newValue, internal_old, internal_new, label, typeField, hasParameters, structure['isFieldComodin']);
    }
    // BUSCAMOS EL ELEMENTO MODIFICADO
    if (id_dsb > 0) parent = id_dsb;
    this.executeFunctionFromForm(parent, structure, internal_new, old, type);
  }
}
