<div *ngIf="loaded && this.dataSource" class="single-table">
  <div style="width: 100%; position: relative;" class="elements-table-paginator">
    <div class="generic-buttons-blue-revert massive-action" style="padding-right: 10px; padding-top: 10px;" *ngIf="this.structure[this.index]['type'] == 'checkbox' && this.selection.selected && this.selection.selected.length && this.selection.selected.length > 0">
      <button mat-button type="button" (click)="massiveAction($event); $event.stopPropagation()">
        <mat-icon *ngIf="!structure[index]['label'] || structure[index]['label'] == ''" style="color: #000;">arrow_right_alt</mat-icon>
        <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
          <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''" style="color: #000;">{{structure[index]['icon']}}</mat-icon>
          {{structure[index]['label']}}
        </ng-container>
      </button>
    </div>
    <button *ngIf="structure[index]['canExportTables'] && this.indexTable > 0" matSuffix mat-icon-button aria-label="Download" (click)="this.exportTableAsExcel(); $event.stopPropagation()" matTooltip="Descargar contenido">
      <mat-icon class="export-icon-init">download</mat-icon>
    </button>
    <mat-paginator [ngClass]="{'hidden': (this.dataSource !== undefined && this.dataSource.filteredData.length == 0) || paginatorOptions === null}" [pageSizeOptions]="paginatorOptions" [pageIndex]="pageIndex" [pageSize]="pageSize" (page)="onPageChange($event)" showFirstLastButtons></mat-paginator>
  </div>
          
  <div [ngClass]="{'generic-taules-0': true, 'list-view-generic': !genericService.isReloadingTabsMenu && !this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['showHeaderTable']}">
    <button class="isShowAsList" *ngIf="!genericService.isReloadingTabsMenu && !this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['showHeaderTable']" (click)="changeShowAsList(); $event.stopPropagation()" matSuffix mat-icon-button aria-label="Hide header" matTooltip="Ocultar cabecera">
      <mat-icon>expand_less</mat-icon>
    </button>
    <button class="isNotShowAsList" *ngIf="!genericService.isReloadingTabsMenu && this.genericService.paramControlVariablesFAs[this.actual_id_functional_area][this.genericService.paramControlVariables[this.actual_id_functional_area]['indexParam']][this.idFa]['showHeaderTable']" (click)="changeShowAsList(); $event.stopPropagation()" matSuffix mat-icon-button aria-label="Show header" matTooltip="Mostrar cabecera">
      <mat-icon>expand_more</mat-icon>
    </button>
    <div [ngClass]="{'generic-taules': true, 'no-click-row': !structure[index]['id_function']}" [ngStyle]="{ 'height': getHeightV + 'px', 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - ' + isHideSideMenu() + 'vw - 38px)' }" [ngStyle.xs]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - ' + isHideSideMenu() + 'vw - 38px - 2.6em - 56px)' }"  [ngStyle.sm]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - ' + isHideSideMenu() + 'vw - 38px - 2.6em - 56px)' }">
      <div [ngClass]="' generic-taules-sub generic-taules-sub-' + structure[index]['id_functional_area'] + '-' + indexTable" [ngStyle]="{ 'height': 'calc(100% - ' + this.getHeightTableV + 'px - 2.6em - 3px)' }">
        <table [ngClass]="'mat-elevation-z2 mat-elevation-z3-' + structure[index]['id_functional_area'] + '-' + indexTable + ' indexTable-' + indexTable" mat-table
          [dataSource]="this.dataSource" matSort multiTemplateDataRows>
          <ng-container *ngFor="let key of displayedColumns; let i = index" matColumnDef="{{ key }}" >
            <!-- Checkbox Column -->
            <ng-container *ngIf="key == '0000-selectorCheckbox'">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? toggleAllRows(this.dataSource.filteredData) : null"
                              [checked]="selection.hasValue() && isAllSelected()"
                              [indeterminate]="selection.hasValue() && !isAllSelected()"
                              [aria-label]="checkboxLabel(this.dataSource.data)">
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row) }">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? this.checkSelectionMultiple(getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(this.dataSource.data, row)">
                </mat-checkbox>
              </td>
            </ng-container>
            
            <ng-container *ngIf="key != '0000-selectorCheckbox'">
              <ng-container *ngIf="!aux[i] || !aux[i].Sort; else elseBlock">
                <th mat-header-cell *matHeaderCellDef [matTooltip]="aux[i].Tooltipp" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
                  <span *ngIf="this.authService.checkUserIsDeveloping == 1 && !structure[index]['creatingMode'] && this.genericService.devModeGeneric && ((structure[index]['child'][aux[i].Id] && conditionsClientService.evaluateIfElements(structure[index], aux[i].Id) && 
                  (conditionsClientService.checkChildActiveHideIntialOnly(structure[index]['child'][aux[i].Id] && conditionsClientService.checkChildActive(structure[index]['child'][aux[i].Id], false, false))) && 
                  structure[index]['child'][aux[i].Id]['id_functional_status_general'] !== 2) || structure[index]['child'][aux[i].Id]['showSub'])" style="position: relative;">
                    <mat-icon [cdkCopyToClipboard]="structure[index]['child'][aux[i].Id]['id_functional_area']" class="debbugingIdsIcon" >info</mat-icon>
                  </span>
                  <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                      <button mat-button (click)="iconClick(aux[i].Id, $event); $event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                      <button mat-button matStepperNext (click)="$event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                      <button mat-button matStepperPrevious (click)="$event.stopPropagation()" style="width: auto;">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                  </ng-container>
                  <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
                </th>
              </ng-container>
              <ng-template #elseBlock>
                <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!aux[i].Sort" [matTooltip]="aux[i].Tooltipp" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}" (click)="onColumnSelectSort(key, dataSource)">
                  <span *ngIf="this.authService.checkUserIsDeveloping == 1 && !structure[index]['creatingMode'] && this.genericService.devModeGeneric && ((structure[index]['child'][aux[i].Id] && conditionsClientService.evaluateIfElements(structure[index], aux[i].Id) && 
                  (conditionsClientService.checkChildActiveHideIntialOnly(structure[index]['child'][aux[i].Id] && conditionsClientService.checkChildActive(structure[index]['child'][aux[i].Id], false, false))) && 
                  structure[index]['child'][aux[i].Id]['id_functional_status_general'] !== 2) || structure[index]['child'][aux[i].Id]['showSub'])" style="position: relative;">
                    <mat-icon [cdkCopyToClipboard]="structure[index]['child'][aux[i].Id]['id_functional_area']" class="debbugingIdsIcon" (click)="this.genericService.consoleLogFA(structure[index]['child'][aux[i].Id])">info</mat-icon>
                  </span>
                  <ng-container *ngIf="!structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] != 'nextStep' && structure[index]['child'][aux[i].Id]['type'] != 'prevStep'">
                      <button mat-button (click)="iconClick(aux[i].Id, $event); $event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'nextStep'">
                      <button mat-button matStepperNext (click)="$event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'prevStep'">
                      <button mat-button matStepperPrevious (click)="$event.stopPropagation()">
                        <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                      </button>
                    </ng-container>
                  </ng-container>
                  <span *ngIf="this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
                </th>
              </ng-template>
              <ng-container *ngIf="structure[index]['child'][aux[i].Id] && structure[index]['child'][aux[i].Id]['bd_field']">
                <td mat-cell *matCellDef="let row" (click)="rowClick($event, row, getRowId(this.dataSource.data.indexOf(row), row.originalRowID))" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row) }">
                  <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'image'; else notImage">
                    <ng-container *ngIf="structure[index]['child'][aux[i].Id]['id_function']; else notFunctionImage">
                      <img (click)="rowClickImage($event, row, getRowId(this.dataSource.data.indexOf(row), row.originalRowID), aux[i].Id); $event.stopPropagation()" class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                    </ng-container>
                    <ng-template #notFunctionImage>
                      <img class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                    </ng-template>
                  </ng-container>
                  <ng-template #notImage>
                    <ng-container *ngIf="checkArray(row[key])">
                      <div *ngFor="let x of row[key]">
                        <span class="material-icons table-copy-content" (click)="$event.stopPropagation()" [cdkCopyToClipboard]="x" matTooltip="Copy">content_copy</span>
                        <span>{{ x === null ? "N/A" : x }}</span>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!checkArray(row[key])">
                      <ng-container *ngIf="row[key] == 'isbooleantrue'">
                        <div><span class="material-icons checked-generic">check_circle</span></div>
                      </ng-container>
                      <ng-container *ngIf="row[key] == 'isbooleanfalse'">
                        <div><span class="material-icons denied-generic">cancel</span></div>
                      </ng-container>
                      <ng-container *ngIf="row[key] != 'isbooleantrue' && row[key] != 'isbooleanfalse'">
                        <div *ngIf="row[key]" class="text-column">
                          <span class="material-icons table-copy-content" (click)="$event.stopPropagation()" [cdkCopyToClipboard]="row[key]" matTooltip="Copy">content_copy</span>
                          <span [innerHTML]="row[key] | safeHtml"></span>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </td>
              </ng-container>
              <ng-container *ngIf="!structure[index]['child'][aux[i].Id] || !structure[index]['child'][aux[i].Id]['bd_field']">
                <td mat-cell *matCellDef="let row; let t = dataIndex" (click)="$event.stopPropagation()" [ngClass]="{ 'highlight': isHighlightRow(row[oldBdField], getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row) }">
                  <ng-container *ngIf="appearProfilePermission(structure[index]['child'][aux[i].Id]['id_functional_area'], this.dataSource.data[t])">
                    <app-generic [structure]="structure[index]['child'][aux[i].Id]" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(this.dataSource.data.indexOf(row), row.originalRowID) + '.' + this.indexTable"></app-generic>
                </ng-container>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
    
          <ng-container *ngIf="displayedColumns && displayedColumns.length && expandedColumn()" matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row; let di = dataIndex" (click)="rowClick($event, row, getRowId(this.dataSource.data.indexOf(row), row.originalRowID))" [attr.colspan]="displayedColumns.length">
              <div style="width: 100%;" *ngIf="row == this.structure[this.index]['expandedElement']" class="element-detail" style="display: flex;" [@detailExpand]="row == this.structure[this.index]['expandedElement'] ? 'expanded' : 'collapsed'">
                <ng-container *ngIf="structure[index]['expandedElement'] !== null && structure[index]['expandedElement'] !== undefined">
                  <app-generic [structure]="expandedCol" [param]="'' + structure[index]['id_functional_area'] + '.' + getRowId(this.dataSource.data.indexOf(row), row.originalRowID) + '.' + this.indexTable" style="width: 100%;"></app-generic>
                </ng-container>
              </div>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky"></tr>
    
          <ng-container *ngIf="expandedCol === null">
            <ng-container *ngIf="this.structure[this.index]['type'] != 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </ng-container>
            <ng-container *ngIf="this.structure[this.index]['type'] == 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="this.checkSelectionMultiple(getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row)"></tr>
            </ng-container>
          </ng-container>
    
          <ng-container *ngIf="expandedCol !== null">
            <ng-container *ngIf="this.structure[this.index]['type'] != 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" [class.example-expanded-row]="this.structure[this.index]['expandedElement'] === row" style="cursor: pointer;" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            </ng-container>
            <ng-container *ngIf="this.structure[this.index]['type'] == 'checkbox'">
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="this.checkSelectionMultiple(getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row)" class="element-row" [class.example-expanded-row]="this.structure[this.index]['expandedElement'] === row" style="cursor: pointer;" ></tr>
              <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" (click)="this.checkSelectionMultiple(getRowId(this.dataSource.data.indexOf(row), row.originalRowID), row)" class="detail-row"></tr>
            </ng-container>
          </ng-container>
          
          <!--<tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
                {{structure[index]['text']}}
              </ng-container>
              <ng-template #noText>
                No se han encontrado resultados.
              </ng-template>
            </td>
          </tr>-->
    
        </table>
        <p *ngIf="loadedAfterView && this.dataSource.filteredData.length == 0" [ngClass]="'mat-elevation-z2 noRowsGeneric noRowsGeneric-' + structure[index]['id_functional_area'] + '-' + indexTable">
          <ng-container *ngIf="structure[index]['text'] !== null && structure[index]['text'] !== undefined; else noText"> 
            <span [innerHTML]="this.structure[this.index]['text'] | safeHtml"></span>
          </ng-container>
          <ng-template #noText>
            No se han encontrado resultados.
          </ng-template>
        </p>
      </div>
    </div>
  </div>
</div>